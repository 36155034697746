import React, { useState, useEffect } from "react";
import useApiServices from "../../Utils/useApiService";
import { jwtDecode } from "jwt-decode";
import swal from "sweetalert";
import { GET_STATIC_QR } from "../../Utils/Constants";
import IconWrapper from "../../Common/IconWrapper/IconWrapper";


/** 
 * This component render static QR and download.
 * @param {function} setCodeImage - this is useState function to update QR image.
 * @returns {ReactNode} - this component returns image for static QR code.
 * */

const QR = ({setCodeImage}) => {
  const { apiCallFnforPost } = useApiServices();
  const Type = localStorage.getItem("typeOfServices");
  const paymentType = Type ? JSON.parse(Type) : null;

  const [hexCode, setHexCode] = useState("");
  const [qrCodeImage, setQRCodeImage] = useState(null);

  const tokenValue = localStorage.getItem("token");
  const token = tokenValue ? tokenValue.slice(1, -1) : null;
  const decodedToken = jwtDecode(token);
  const { mid } = decodedToken;

  const generateQRCode = async () => {
    try {
      // Convert base64 string to Blob
      const byteCharacters = atob(hexCode);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const qrCodeBlob = new Blob([byteArray], { type: "image/png" });

      setQRCodeImage(URL.createObjectURL(qrCodeBlob));
      setCodeImage(URL.createObjectURL(qrCodeBlob));
    } catch (error) {
      console.error("Error:", error);
      swal("Error", "An error occurred while generating QR code", "error");
    }
  };

  const getStaticQr = async (token) => {
    try {
      // const response = await GetStaticQr(token); // Add the await keyword here
      const response = await apiCallFnforPost(GET_STATIC_QR);
      if (response.statusCode === "OK") {
        setHexCode(response.responseData);
      } else {
        setHexCode([]);
      }
    } catch (error) {}
  };

 
  useEffect(() => {
    getStaticQr(token);
  }, []);

  useEffect(() => {
    generateQRCode();
  }, [hexCode]);

  return (
    <>
      {/* <div class="content-body">
        <div class="container-fluid"> */}
        

      <div class="row">
        <div class="col-12">
          

          {true ? (
            <div className="d-flex justify-content-center align-items-center">
              {qrCodeImage && <img src={qrCodeImage} alt="QR Code" />}
            </div>
          ) : (
            <h3>Service not found!</h3>
          )}
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default QR;
