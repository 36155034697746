import React, { useState } from "react";



  
/**
 * This component is resuable CopyButton component it copies the given text..
 *
 * @param {string} text .
 * @returns {ReactNode} A React component that renders CopyButton.
 */


const CopyButton = ({ text }) => {
  const [status, setStatus] = useState(false);

  const copyUrlToClipboard = () => {
    // Create a temporary textarea element
    var textarea = document.createElement("textarea");
    // Set its value to the text you want to copy
    textarea.value = text;
    // Make sure it's not visible
    textarea.style.position = "fixed";
    textarea.style.opacity = 0;
    // Append the textarea to the DOM
    document.body.appendChild(textarea);
    // Select the text within the textarea
    textarea.select();
    // Execute the copy command
    document.execCommand("copy");
    // Clean up: remove the textarea from the DOM
    document.body.removeChild(textarea);
    setStatus(true);
      setTimeout(() => {
          setStatus(false);
      }, 2000);
  };
  return (
    <div onClick={copyUrlToClipboard}>
      {status ? (
        <i class="fa-solid fa-circle-check text-primary"></i>
      ) : (
        <i class="fa-regular fa-copy"></i>
      )}
    </div>
  );
};

export default CopyButton;