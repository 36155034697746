import { useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import {
  requestSent,
  requestFailed,
  responseReceived,
} from "../store/Slices/utilSlice";

const useApiServices = () => {
  const dispatch = useDispatch();
  // const { token } = useSelector((state) => state.userData);
  // console.log("token", token);
  const [loading, setLoading] = useState(false);

  const token = Cookies.get("access_token");

  const instance = axios.create();

  instance.interceptors.request.use(
    (config) => {
      setLoading(true);
       
      dispatch(requestSent());
      // Customize request config, such as setting headers
      // const token = localStorage.getItem("token");
      if (token && config.incHead) {
        config.headers.Authentication = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      setLoading(false);
      dispatch(requestFailed());
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response) => {
      setLoading(false);
      dispatch(responseReceived());
      return response.data;
    },
    (error) => {
      setLoading(false);
      dispatch(requestFailed());
      return Promise.reject(error);
    }
  );

  const apiCallFnforPost = async (url, payload) => {
    try {
      const response = await instance.post(url, payload, {
        incHead: true,
      });
      return response;
    } catch (error) {
      console.error("Error sending:", error);
      throw error;
    }
  };

  const apiCallFnforPostWithParams = async (url, payload, param) => {
    try {
      const response = await instance.post(
        url,
        payload,
        {
          params: param,
        },
        {
          incHead: true,
        }
      );
      return response;
    } catch (error) {
      console.error("Error sending:", error);
      throw error;
    }
  };

 const FileUploadAsFormData = async (url, file, mid) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("mid", mid);
      const response = await axios.post(url, formData, {
        incHead: true,
      });
      // console.log(response.data);
      return response?.data;
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };

  const apiCallFnforPut = async (url, payload) => {
    try {
      const response = await instance.put(url, payload, {
        incHead: true,
      });
      return response;
    } catch (error) {
      console.error("Error sending:", error.message);
      throw error;
    }
  };

  const apiCallFnforPostForOpenUrl = async (url, payload) => {
    try {
      const response = await instance.post(url, payload, {
        incHead: false,
      });
      return response;
    } catch (error) {
      console.error("Error sending:", error);
      throw error;
    }
  };

  const apiCallFnforGet = async (url) => {
    
    try {
      const response = await instance.get(url, {
        incHead: true,
      });

      return response;
    } catch (error) {
      console.error("Error receiving:", error.message);
      throw error;
    }
  };

  const apiCallFnforDelete = async (url) => {
    try {
      const response = await instance.delete(url, {
        incHead: true,
      });

      return response;
    } catch (error) {
      console.error("Error receiving:", error.message);
      throw error;
    }
  };

  const apiCallFnforPutForOpenUrl = async (url, payload) => {
    try {
      const response = await instance.put(url, payload, {
        incHead: false,
      });
      return response;
    } catch (error) {
      console.error("Error sending:", error);
    }
  };

  const apiCallFnforGetOpenUrl = async (url) => {
    try {
      const response = await instance.get(url, {
        incHead: false,
      });
      return response?.data;
    } catch (error) {
      console.error("Error sending:", error);
    }
  };

  // Define other API service functions here...

  return {
    loading,
    apiCallFnforPost,
    apiCallFnforGet,
    apiCallFnforPut,
    apiCallFnforPutForOpenUrl,
    apiCallFnforGetOpenUrl,
    apiCallFnforPostForOpenUrl,
    apiCallFnforDelete,
    FileUploadAsFormData,
    // Other API service functions
  };
};

export default useApiServices;
