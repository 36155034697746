import { config, getCompleteDomain, getServerEnvironment } from "./DynamicPortalFunctions";


const {Url}=config(getServerEnvironment(getCompleteDomain()))
 
  export const SWIPELINC_API =  Url

export const LOGIN_API =`${SWIPELINC_API}MerchantPanel/merchant/login`;
export const IMG_SLIDE_PATH =`${SWIPELINC_API}uploads/`;

export const CHANGE_PASSWORD =
`${SWIPELINC_API}MerchantPanel/merchant/passwordreset/changePassword`;

export const FORGOT_PASSWORD_API =
`${SWIPELINC_API}MerchantPanel/merchant/passwordreset/forgotPassword`;

export const OTP_VERIFY_API =
`${SWIPELINC_API}MerchantPanel/merchant/passwordreset/otpverify`;

export const VERIFY_MERCHANT_ONBOARD_EMAIL = 
`${SWIPELINC_API}MerchantPanel/merchant/onboard/verified/email`;

export const  MERCHANT_ACCOUNT_DETAIL = 
`${SWIPELINC_API}MerchantPanel/merchant/Util/getAccountDetail`;

export const VERIFY_MERCHANT_ONBOARD_MOBILE = 
`${SWIPELINC_API}MerchantPanel/merchant/onboard/verified`;


export const GET_NOTIFICATION_BY_MID= 
`${SWIPELINC_API}MerchantPanel/merchant/notification/findByMid//`;

export const DISABLE_NOTIFICATION_BY_ID= 
`${SWIPELINC_API}MerchantPanel/merchant/notification/Disable/`;

export const MERCHANT_ONBOARD_SAVE = 
`${SWIPELINC_API}MerchantPanel/merchant/onboard/save`;

export const MERCHANT_ONBOARD_VERIFIED = 
`${SWIPELINC_API}MerchantPanel/merchant/onboard/verified`;

export const MERCHANT_ONBOARD_OTP_CHANGE_EMAIL = 
`${SWIPELINC_API}MerchantPanel/merchant/verify-email/emailVerify`;

export const MERCHANT_ONBOARD_OTP_CHANGE_MOBILE = 
`${SWIPELINC_API}MerchantPanel/merchant/verify-email/mobileVerify`;

export const SEND_OTP_TO_MOBILE = 
`${SWIPELINC_API}MerchantPanel/merchant/verify-email/sendotpmobile`;

export const SEND_OTP_TO_EMAIL = 
`${SWIPELINC_API}MerchantPanel/merchant/verify-email/send-otp`;

export const CHECK_EMAIL_IS_VERIFIED_OR_NOT = 
`${SWIPELINC_API}MerchantPanel/merchant/onboard/verified/email`;

export const CHECK_MOBILE_IS_VERIFIED_OR_NOT = 
`${SWIPELINC_API}MerchantPanel/merchant/onboard/verified`;

export const GET_DASHBOARD_API =
`${SWIPELINC_API}MerchantPanel/merchant/dashboard/getdashboard/`;

export const GET_DASHBOARD_AMOUNT_API =
`${SWIPELINC_API}MerchantPanel/merchant/dashboard/getamountdata/`;

export const FETCH_MERCHANT_PROFILE = `${SWIPELINC_API}MerchantPanel/merchant/merchant-profile/getByMid/`;
     

export const VERIFY_CALLBACK_URLS = `${SWIPELINC_API}MerchantPanel/merchant/Util/getStatus`;
     
export const VIEW_PAYOUT_REQUEST_UPI_BY_MID =
`${SWIPELINC_API}MerchantPanel/merchant/payoutRagister/findByMidWithStatusNotPending/`;

export const PAN_VERIFICATION = 
`${SWIPELINC_API}MerchantPanel/merchant/ekyc/panVerification`;

export const FETCH_REGISTRATION_TYPES =
`${SWIPELINC_API}MerchantPanel/merchant/registration/getAllRegistrationType`;

export const FETCH_BUSINESS_CATEGORIES =
`${SWIPELINC_API}MerchantPanel/merchant/business/getAllBusinessType`;

export const FETCH_BUSINESS_TYPE =
`${SWIPELINC_API}MerchantPanel/merchant/company/getAllCompanyType`;

export const ADHAR_VERIFICATION = 
`${SWIPELINC_API}MerchantPanel/merchant/ekyc/aadhaarVerification`;

export const GST_VERIFICATION = 
`${SWIPELINC_API}MerchantPanel/merchant/ekyc/gstVerification`;

export const ADHAR_OTP_VERIFICATION = 
`${SWIPELINC_API}MerchantPanel/merchant/ekyc/aadhaarOtpVerification`;

export const FETCH_ALL_PAYMENT_TYPES = 
`${SWIPELINC_API}MerchantPanel/merchant/paymentType/getALLPaymentType`;

export const MERCHANT_ONBOARD_UPDATE = 
`${SWIPELINC_API}MerchantPanel/merchant/onboard/update`;

export const GUARANTEE_REPORT_FETCH = 
`${SWIPELINC_API}MerchantPanel/admin/guarantAmount/findAllByMid/`;

export const GET_ALL_RECONCIALIATION_DATA = `${SWIPELINC_API}MerchantPanel/merchant/reconsilation/getAll/reconciliationData`;

export const  WITHDRAW_REQUEST_FETCH = 
`${SWIPELINC_API}MerchantPanel/merchant/withdrawal/findByMidWithStatusNotPending/`;


export const WALLET_BALANCE_REPORT_API = `${SWIPELINC_API}MerchantPanel/merchant/wallet/findByStatuss/`;

export const FETCH_RELEASE_BY_MID = `${SWIPELINC_API}MerchantPanel/merchant/release/getBy/`;

export const FETCH_HOLD_BY_MID = `${SWIPELINC_API}MerchantPanel/merchant/hold/getHoldReport/`;

export const GET_ALL_SETTLEMET_ADJUSTMENT_DATA = `${SWIPELINC_API}/MerchantPanel/merchant/settlement/get-by-mid-and-remark/`;

export const FETCH_DEPOSIT_BY_MID = `${SWIPELINC_API}MerchantPanel/merchant/deposit/getDepositeReportByMid/`;

export const ACC_NUM_IFSC_VERIFY_API =
`${SWIPELINC_API}MerchantPanel/merchant/ekyc/bankVerification`;

export const IFSC_VERIFY_API =
`${SWIPELINC_API}MerchantPanel/merchant/ekyc/findByIfsc`;

export const MY_REFFERELS =
`${SWIPELINC_API}MerchantPanel/merchant/onboard/myReferal/`;

export const  LOG_OUT_API =
`${SWIPELINC_API}/MerchantPanel/merchant/login/logout`;

export const UPDATE_MERCHANT_BANK_DETAILS = 
`${SWIPELINC_API}MerchantPanel/merchant/onboard/savebankDetails`;

export const UPLOAD_MERCHANT_DOCS = 
`${SWIPELINC_API}MerchantPanel/merchant/onboard/savemerchantdocuments`;

export const CHECK_FILE_IS_PDF = 
`${SWIPELINC_API}MerchantPanel/merchant/onboard/checkPdf`;

export const GET_REPORT_API =
`${SWIPELINC_API}MerchantPanel/MerchantPanel/LiveReport/allReports/tmeframe`;

export const GET_REPORT_API_CSV_TIMEFRAME =
`${SWIPELINC_API}MerchantPanel/MerchantPanel/LiveReport/allReports/tmeframe/withoutPagination`;


export const CHANGE_PASSWORD_AFTER_LOGIN = 
`${SWIPELINC_API}MerchantPanel/merchant/onboard/changePassword`;

export const GET_ALL_SETTLEMENT = 
`${SWIPELINC_API}MerchantPanel/merchant/settlement/settlement/findBymid/`;

export const VIEW_E_KYC_REPORT = 
`${SWIPELINC_API}MerchantPanel/merchant/ekyc/userUses`;

export const FETCH_ALL_VENDERS_BY_MID =
   `${SWIPELINC_API}MerchantPanel/merchant/vendor/getAllVendorByMid/`;

export const FETCH_ALL_EKYC =
   `${SWIPELINC_API}MerchantPanel/merchant/ekyc/userUses`;

export const FETCH_ALL_PAYIN_SERVICES =
   `${SWIPELINC_API}/MerchantPanel/merchant/dashboard/getpaymentserviceswithpercentage/`;

export const GET_STATIC_QR = `${SWIPELINC_API}/MerchantPanel/merchant/virtual-terminal/request/qr` ;

export const POST_LINK_BASED_PAYMENT =  `${SWIPELINC_API}MerchantPanel/merchant/link/save`;

export const   VIRTUAL_TERMINAL_REQUEST_SUBMIT = 
   `${SWIPELINC_API}/MerchantPanel/merchant/virtual-terminal/request`;

   export const CHECK_THIRD_PARTY_API_STATUS = 
`${SWIPELINC_API}MerchantPanel/merchant/third-party-service/get`;

export const VERIFY_UPI_API =
 `${SWIPELINC_API}MerchantPanel/merchant/ekyc/VPAVerification`;

 export const VERIFY_VENDER_EMAIL =
 `${SWIPELINC_API}MerchantPanel/merchant/vendor/findByEmail/`;

 export const VERIFY_VENDER_MOBILE =
`${SWIPELINC_API}MerchantPanel/merchant/vendor/findByMobile/`;

export const VERIFY_VENDER_AADHAAR =
  `${SWIPELINC_API}MerchantPanel/merchant/ekyc/aadhaarVerification`;

  export const VERIFY_VENDER_AADHAAR_OTP =
  `${SWIPELINC_API}MerchantPanel/merchant/ekyc/aadhaarOtpVerification`;

  export const VERIFY_VENDER_PAN =
  `${SWIPELINC_API}MerchantPanel/merchant/ekyc/panVerification`;

  export const SAVE_VENDER_DETAILS =
  `${SWIPELINC_API}MerchantPanel/merchant/vendor/save`;

  export const SETTLEMENT_MULTIPLE_FILTER_API = 
`${SWIPELINC_API}MerchantPanel/merchant/settlement/settlement/getSettlementByMultipalCondition`;


export const WITHDRAW_MULTIPLE_FILTER_API = 
`${SWIPELINC_API}MerchantPanel/merchant/withdrawal/ByMultpalCondition`;


export const CHARGEBACK_MULTIPLE_FILTER_API = 
`${SWIPELINC_API}MerchantPanel/merchant/chargeback/chargeBack/ByMultpalCondition`;

export const GURANTEE_MULTIPLE_FILTER_API = 
`${SWIPELINC_API}MerchantPanel/admin/guarantAmount/ByMultpalCondition`;

export const PAYOUT_MULTIPLE_FILTER_API = 
`${SWIPELINC_API}MerchantPanel/merchant/payoutRagister/getPayoutByMultipleCondition`;

export const RECONCILIATION_MULTIPLE_FILTER_API = 
`${SWIPELINC_API}MerchantPanel/merchant/reconsilation/getReconciliationByMultipleConditions`;

export const FETCH_DATA_FOR_CHARGEBACK=
`${SWIPELINC_API}MerchantPanel/merchant/chargeback/findbymid/`;


export const GET_REPORT_BY_CONDITION_API =
`${SWIPELINC_API}MerchantPanel/merchant/reports/getreportByCondition`;


export const PAYIN_MULTIPLE_FILTER_API =
`${SWIPELINC_API}MerchantPanel/merchant/reports/getreportByMultpalCondition`;

export const ADD_WALLET_BALANCE_API =
`${SWIPELINC_API}MerchantPanel/merchant/wallet/save`;


export const SAVE_USER = `${SWIPELINC_API}MerchantPanel/merchant/subuser/save`;


export const USER_ENABLE_DISABLE = `${SWIPELINC_API}/MerchantPanel/merchant/subuser/` ;


export const GET_ALL_USER = `${SWIPELINC_API}/MerchantPanel/merchant/subuser/getAllSubUsers/`;

export const GET_ALL_PRIVILAGES = `${SWIPELINC_API}MerchantPanel/merchant/privileg/getallwithsubprivileges`;
 
export const USER_UPDATE = `${SWIPELINC_API}/MerchantPanel/merchant/subuser/subUsersUpdate` ;

export const SAVE_MISSING_CALLBACK = 
`${SWIPELINC_API}MerchantPanel/merchant/missingCallback/save`;


export const FETCH_ALL_VENDERS_BY_STATUS_AND_MID =
`${SWIPELINC_API}MerchantPanel/merchant/vendor/getAllVendorByStatus`;

export const  UPLOAD_PAYOUT_TXN_CALLBACK_CSV = `${SWIPELINC_API}MerchantPanel/merchant/payoutRagister/csv/upload`;

export const WITHDRAWL_SAVE_PAYOUT= `${SWIPELINC_API}/MerchantPanel/merchant/withdrawal/save`

export const BALANCE_FETCH = 
`${SWIPELINC_API}MerchantPanel/merchant/payoutRagister/getBalance/`;

export const TRANSFER_AMOUNT = 
`${SWIPELINC_API}/MerchantPanel/merchant/withdrawal/save`;

export const  PENDING_WITHDRAW_REQUEST_FETCH = 
`${SWIPELINC_API}/MerchantPanel/merchant/withdrawal/getWithdrawalByMidForAllTransactionStatusPending/`;

export const  NOTIFICATION_DELETE_API = 
`${SWIPELINC_API}MerchantPanel/merchant/notification/Disable/`;

export const UPLOAD_CALLBACK_CSV = `${SWIPELINC_API}MerchantPanel/merchant/missingCallback/csv/upload`;

export const FIND_BY_ORDER_NO =
`${SWIPELINC_API}MerchantPanel/merchant/reports/findByOrderNo`;

export const AVAILABLE_BALANCE_UPI_IMPS_WALLET =
`${SWIPELINC_API}/MerchantPanel/merchant/payoutRagister/getBalance/`;

export const GET_ALL_PAYOUT_REQUEST = 
`${SWIPELINC_API}/MerchantPanel/merchant/payoutRequest/getAll/`;

export const  PAYOUT_REQUEST_DELETE = 
`${SWIPELINC_API}/MerchantPanel/merchant/payoutRequest/delete/`;


 

export const FIND_VENDOR_OR_CUSTOMER = `${SWIPELINC_API}MerchantPanel/merchant/vendor/`;

export const FETCH_FRM_USERS = `${SWIPELINC_API}MerchantPanel/merchant/frm-block/get/`;


export const FRM_BLOCK_USER = `${SWIPELINC_API}MerchantPanel/merchant/frm-block/save`;


export const API_DASHBOARD_WITH_CONDITION = `${SWIPELINC_API}MerchantPanel/merchant/dashboard/combined-api`;

export const TREND_CHART_API = `${SWIPELINC_API}MerchantPanel/merchant/dashboard/getdashboardTrendchart/`;

export const REPORTS_BY_TIMEFRAME = `${SWIPELINC_API}/MerchantPanel/MerchantPanel/LiveReport/allReports/tmeframe`;

export const REPORT_ERROR_CODES = `${SWIPELINC_API}/MerchantPanel/MerchantPanel/LiveReport/getDataBy/tmeframe`;

export const GET_DOWNLOAD_CSV_OF_ARCHIEVE_REPORT = `${SWIPELINC_API}/MerchantPanel/merchant/CSV/Download/payin`;

export const BOX_DATA_BY_CONDITION = `${SWIPELINC_API}/MerchantPanel/MerchantPanel/LiveReport/getDataBy/condition` ;

export const DECRYPT_CODE = `${SWIPELINC_API}/MerchantPanel/paymentRequest/webBased`;

export const CALLBACK_URL = `${SWIPELINC_API}/MerchantPanel/paymentRequest/callBackUrl/status`;

export const FETCH_VENDER_DETAILS_BY_VENDER_ID =`${SWIPELINC_API}MerchantPanel/merchant/vendor/getVendorById/`;

export const PAYOUT_URL = `${SWIPELINC_API}MerchantPanel/paymentRequest/payout`;

export const COMPANY_PAN_VERIFICATION = `${SWIPELINC_API}MerchantPanel/paymentRequest/ekyc/panVerification`;

export const COMPANY_ADHAAR_VERIFICATION = `${SWIPELINC_API}MerchantPanel/paymentRequest/ekyc/aadhaarVerification`;

export const COMPANY_AADHAR_OTP_VERIFICATION  = `${SWIPELINC_API}MerchantPanel/paymentRequest/ekyc/aadhaarOtpVerification`;

export const COMPANY_GST_VERIFICATION = `${SWIPELINC_API}MerchantPanel/paymentRequest/ekyc/gstVerification`;

export const BANK_VERIFICATION = `${SWIPELINC_API}MerchantPanel/paymentRequest/ekyc/bankVerification`

export const GET_ALL_NOTICE = `${SWIPELINC_API}MerchantPanel/merchant/notification/images/notification`

export const FRM_URL = `${SWIPELINC_API}MerchantPanel/paymentRequest/frmMethod`;

export const PAYOUT_REQ_SAVE = `${SWIPELINC_API}MerchantPanel/merchant/payoutRagister/save`;

export const SAVE_PAYOUT_REQUEST = `${SWIPELINC_API}MerchantPanel/merchant/payoutRequest/save`;

export const GET_ALL_DEBIT = `${SWIPELINC_API}MerchantPanel/getAllDebit`;

export const GET_ALL_CREDIT = `${SWIPELINC_API}MerchantPanel/getAll`;

export const ONBOARDING_URL = `${SWIPELINC_API}MerchantPanel/merchant/onboard/saveMerchantDetails`;

export const SAVE_IMAGE_URL = `${SWIPELINC_API}MerchantPanel/merchant/onboard/upload`;

export const ACCEPT_OR_REJECT = `${SWIPELINC_API}/MerchantPanel/merchant/reports/findByOrderNumber` ;

export const  GET_RESPONSE_CODE= `${SWIPELINC_API}MerchantPanel/admin/payin/findByTxnId/` ;

export const  PAYOUT_BALANCE_DEATILS= `${SWIPELINC_API}/MerchantPanel/merchant/merchantLoginMasterController/findByMid/` ;

export const SEND_CALLBACK = `${SWIPELINC_API}MerchantPanel/merchant/sendPayinCallback/` ;

export const UPDATE_PROFILE = `${SWIPELINC_API}MerchantPanel/merchant/merchant-profile/updateProfile`;

