import axios from "axios";
import {
  CHECK_FILE_IS_PDF,
  IFSC_VERIFY_API,
  UPDATE_MERCHANT_BANK_DETAILS,
  UPLOAD_MERCHANT_DOCS,
  VERIFY_MERCHANT_ONBOARD_EMAIL,
  VERIFY_MERCHANT_ONBOARD_MOBILE,
} from "./Constants";
import { jwtDecode } from "jwt-decode";

const tokenValue = localStorage.getItem("token");
export const token = tokenValue ? tokenValue.slice(1, -1) : null;

const Header = {
  headers: {
    Authentication: "Bearer " + token,
  },
};

const Header2 = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

export const apiCallFnforPost = async (url, payload) => {
  try {
    const response = await axios.post(url, payload, Header);
    return response?.data;
  } catch (error) {
    console.error("Error sending:", error);
  }
};

export const apiCallFnforPostForOpenUrl = async (url, payload) => {
  try {
    const response = await axios.post(url, payload);
    return response?.data;
  } catch (error) {
    console.error("Error sending:", error);
  }
};

export const apiCallFnforPostWithMID = async (url, payload, param) => {
  try {
    const response = await axios.post(url, payload, {
      params: param,
    });
    return response?.data;
  } catch (error) {
    console.error("Error sending:", error);
  }
};

export const apiCallFnforGetOpenUrl = async (url) => {
  try {
    const response = await axios.get(url);
    return response?.data;
  } catch (error) {
    console.error("Error Received:", error);
  }
};

export const apiCallFnforGet = async (url) => {
  try {
    const response = await axios.get(url, Header);
    return response?.data;
  } catch (error) {
    console.error("Error Received:", error);
  }
};

export const apiCallFnforPutForOpenUrl = async (url, payload) => {
  try {
    const response = await axios.put(url, payload);
    return response?.data;
  } catch (error) {
    console.error("Error sending:", error);
  }
};

export const apiCallFnforPut = async (url, payload) => {
  try {
    const response = await axios.put(url, payload,{});
    return response?.data;
  } catch (error) {
    console.error("Error sending:", error);
  }
};

export const updateMerchantBankDetails = async (payload) => {
  try {
    const response = await axios.put(UPDATE_MERCHANT_BANK_DETAILS, payload);

    return response.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const uploadMerchantDocs = async (mid, docsName, docsFile) => {
  // alert("called");
  try {
    const formData = new FormData();
    formData.append("mid", mid);

    // formData.append("fileName", [...docsName]);
    // formData.append("pdfFiles", docsFile);

    docsFile.forEach((file) => {
      formData.append(`pdfFiles`, file);
    });
    docsName.forEach((name) => {
      formData.append(`fileName`, name);
    });

    const response = await axios.post(`${UPLOAD_MERCHANT_DOCS}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    // console.log(response);
    return response?.data?.statusCode;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};

export const ifscCodeVerifier = async (ifscCode) => {
  try {
    const res = await axios.post(IFSC_VERIFY_API, { ifscCode });
    return { data: res?.data?.data, status: res?.data?.statusCode };
  } catch (error) {
    throw new Error("Error occurred while fetching data");
  }
};

export const checkIsFileIsPDF = async (file) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    const response = await axios.post(`${CHECK_FILE_IS_PDF}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    // console.log(response?.data?.statusCode);
    return response?.data?.statusCode;
  } catch (error) {
    console.error("Error sending OTP:", error);
  }
};
