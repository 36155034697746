import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIsAuthenticated } from "../store/Slices/userSlice";
import Card from "./Card";
import MiddleCard from "./MiddleCard";
import PreviousTxns from "./PreviousTxns";
import VirtualAssistant from "./VirtualAssistant";
import TotalTransaction from "./TotalTransaction";
 

import { jwtDecode } from "jwt-decode";
import {
  API_DASHBOARD_WITH_CONDITION,
  FETCH_ALL_PAYIN_SERVICES,
  GET_DASHBOARD_AMOUNT_API,
  REPORT_ERROR_CODES,
} from "../Utils/Constants";
import useApiServices from "../Utils/useApiService";
import Icon1 from "../Assets/images/bank3.png";
import Icon2 from "../Assets/images/bank50.png";
import Icon3 from "../Assets/images/credit50.png";
import Icon4 from "../Assets/images/creditcard32.png";

import PieCharts from "./Charts/PieChart";

import FloatingIcon from "../Common/FloatingIcon/Floating";
import SimpleBarChart from "./Charts/BarChart";

import { errorCodes, serviceColors } from "../Utils/ContantKeys";
import Shimmer from "../Common/Shimmer/Shimmer";
import CardPayout from "./CardPayout";
import { NoticeComponent } from "../Common/Swiper/NoticeComponent";
 

const DashBoard = () => {
  const [boxData, setBoxData] = useState([]);
  const [combinedApiRes, setCombinedApiRes] = useState([]);
  const [payinServices, setPayinServices] = useState(null);
  const [servicesPieChartData, setServicesPieChartData] = useState([]);
  const [errorCodelist, setErrorCodeList] = useState([]);
  const [totalTransactionData, setTotalTransactionData] = useState([]);
  const [unChangedRes, setUnChangedRes] = useState([]);
  const [availablePayoutBalance, setAvailablePayoutBalance] = useState("");

  const { apiCallFnforPost, apiCallFnforGet } = useApiServices();
  const tokenValue = localStorage.getItem("token");
  const token = tokenValue ? tokenValue.slice(1, -1) : null;
  const decodedToken = jwtDecode(token);
  const { mid } = decodedToken;

  const [condition, setCondition] = useState("this year");

  useEffect(() => {
    setBoxData({});
    setTotalTransactionData({});
    setAvailablePayoutBalance({});
    getDataCombined();
    getDataPayinServices();
    getErrorCodeData();
  }, [condition]);

  useEffect(() => {
    getNotChangingData();
  }, []);

  useEffect(() => {
    DistributeData(combinedApiRes);
  }, [combinedApiRes]);

  const DistributeData = (combinedApiRes) => {
    const data = {
      mid: combinedApiRes.mid,
      totalCount: combinedApiRes.totalCount,
      totalAmt: combinedApiRes.totalAmt,
      successCount: combinedApiRes.successCount,
      failureCount: combinedApiRes.failureCount,
      declined: combinedApiRes.declined,
      pendingCount: combinedApiRes.pendingCount,
      filterTxnList: combinedApiRes.filterTxnList,
      payoutTotalCount:combinedApiRes.payoutTotalCount,
      payoutSuccessCount:combinedApiRes.payoutSuccessCount,
      payoutFailureCount:combinedApiRes.payoutFailureCount,
      payoutPendingCount:combinedApiRes.payoutPendingCount,
    };

    const data2 = {
      settlmentAmout: combinedApiRes?.settelmentAmountNetAMtToWallet,
      settlementCharge: combinedApiRes?.settlementCharge,
      settlmentGstCharge: combinedApiRes?.settlmentGstCharge,
      depositAmount: combinedApiRes?.securityDepositAmount,
      depositeToWallet: combinedApiRes?.depositeToWallet,
      depositeToPayout: combinedApiRes?.depositeToPayout,
      totalCommisionEarnedAmount: combinedApiRes?.totalCommisionEarnedAmount,
      chragebackAmount: combinedApiRes?.chragebackAmount,
      holdAmount: combinedApiRes?.holdAmount,
      withdrawalAmount: combinedApiRes?.withdrawalAmount,
      withDrawTopayout: combinedApiRes?.withDrawTopayout,
      withDrawToAccount: combinedApiRes?.withDrawToAccount,
      withdrawnTocredit: combinedApiRes?.dropCreditAmount,
      payoutGstCharge: combinedApiRes?.payoutGstCharge,
      payoutServiceCharge: combinedApiRes?.payoutServiceCharge,
      payAmount: combinedApiRes?.payAmount,
      totalwalletBalance: combinedApiRes?.totalwalletBalance,
       
      securityDepositAmount: combinedApiRes?.securityDepositAmount,
      guranteeAmount: combinedApiRes?.guranteeAmount,
      dropCreditAmount: combinedApiRes?.dropCreditAmount,
    };

    const data3 = {
      upiAmount: combinedApiRes.map.upiAmount,
      wallet: unChangedRes.wallet,
      impsAmount: unChangedRes.impsAmount,
    };

    setBoxData(data2);
    setTotalTransactionData(data);
    setAvailablePayoutBalance(data3);
  };

  const { privilagesData } = useSelector((state) => state.privilages);

  const getDataCombined = async () => {
    const paylaod = {
      mid: mid,
      timeFrame: condition,
    };
    try {
      const response = await apiCallFnforPost(
        API_DASHBOARD_WITH_CONDITION,
        paylaod
      );
      if (response.statusCode === 200) {
        setCombinedApiRes(response?.responseData);
      } else {
        setCombinedApiRes([]);
      }
    } catch (error) {
      setBoxData(true);
      // console.error(error);
    }
  };

  const getNotChangingData = async () => {
    try {
      const response = await apiCallFnforGet(GET_DASHBOARD_AMOUNT_API + mid);
      if (response.statusCode === 200) {
        setUnChangedRes({
          ...response?.responseData,
          ...response?.responseData2,
        });
        // setUnChangedRes((prev) => {
        //   return {
        //     ...prev,
        //     ...response?.responseData2,
        //   };
        // });
      } else {
        setUnChangedRes([]);
      }
    } catch (error) {
      setUnChangedRes(true);
      // console.error(error);
    }
  };

  const getDataPayinServices = async () => {
    const payload = {
      mid: mid,
      timeFrame: condition,
    };
    try {
      const response = await apiCallFnforPost(
        FETCH_ALL_PAYIN_SERVICES,
        payload
      );
      if (response.statusCode === 200) {
        setPayinServices(response?.responseData);
     
        setServicesPieChartData(
          response?.responseData?.map((item, i) => {
            return {
              id: i + 1,
              label: item.paymentService,
              value: item.percentage,
              color: serviceColors[item.paymentService],
            };
          })
        );
      } else {
        setPayinServices([]);
        setServicesPieChartData([]);
      }
    } catch (error) {
      setPayinServices([]);
      setServicesPieChartData([]);
      // console.error(error);
    }
  };

  const getErrorCodeData = async () => {
    const payload = {
      mid: mid,
      timeFrame: condition,
    };
    try {
      const response = await apiCallFnforPost(REPORT_ERROR_CODES, payload);
      if (response?.statusCode === 200) {
        setErrorCodeList(
          response?.data?.map((item, i) => {
            return {
              id: i + 1,
              label: item.evokTxnCode,
              value: item.count,
              color: errorCodes[item.evokTxnCode],
            };
          })
        );
      } else {
        setErrorCodeList([]);
      }
    } catch (error) {
      setErrorCodeList([]);
      // console.error(error);
    }
  };

  useEffect(() => {
    getDataPayinServices();
  }, []);

  return (
    <div class="content-body">
      <FloatingIcon setCondition={setCondition} condition={condition} />
      <div class="container-fluid">
        <div class="row invoice-card-row">
          <Card
            name="Total Payin"
            amount={totalTransactionData?.totalAmt}
            color={"bg-primary"}
            image={Icon1}
          />
          <CardPayout
            name="Payout Balance"
            amount={unChangedRes}
            color={"bg-secondary"}
            image={Icon2}
          />
          <Card
            name="Credit Balance"
            amount={unChangedRes?.dropCreditAmount}
            color={"bg-info"}
            image={Icon3}
          />
          <Card
            name="Total Chargeback"
            amount={boxData?.chragebackAmount}
            color={"bg-warning"}
            image={Icon4}
          />
        </div>
        <div class="row">
          <MiddleCard
            condition={condition}
            data={unChangedRes?.totalwalletBalance}
            payinServices={payinServices}
          />
          <PieCharts
            condition={condition}
            data={servicesPieChartData}
            type={"services"}
          />
          <PreviousTxns
            condition={condition}
            data={boxData}
            data2={unChangedRes?.totalwalletBalance}
            payIn={totalTransactionData?.totalAmt}
          />
          <div class="col-lg-6 col-xl-6 col-xxl-6">
            <div class="row">
              <VirtualAssistant />
              <TotalTransaction
                title="Payin Total Txn"
                data={totalTransactionData?.totalCount}
              />
              <TotalTransaction
                title="Payin Success Txn"
                data={totalTransactionData?.successCount}
              />
              <TotalTransaction
                title="Payin Failed Txn"
                data={totalTransactionData?.failureCount}
              />
              <TotalTransaction
                title="Payin Declined Txn"
                data={totalTransactionData?.declined}
              />
              <TotalTransaction
                title="Payin Pending Txn"
                data={totalTransactionData?.pendingCount}
              />
               <TotalTransaction
                title="Payout Total Txn"
                data={totalTransactionData?.payoutTotalCount}
              />
              
              <TotalTransaction
                title="Payout Failed Txn"
                data={totalTransactionData?.payoutFailureCount}
              />
              <TotalTransaction
                title="Payout Success Txn"
                data={totalTransactionData?.payoutSuccessCount}
              />
              <TotalTransaction
                title="Payout Pending Txn"
                data={totalTransactionData?.payoutPendingCount}
              />
          
            </div>
          </div>
        </div>
        <div className="row">
          {/* <Graphs />  */}
          {/* <LineCharts condition={condition}/> */}
          <SimpleBarChart condition={condition} />

          <PieCharts condition={condition} data={errorCodelist} />
        </div>
      </div>
    </div>
  );
};

export default DashBoard;
