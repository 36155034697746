import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { setIsAuthenticated, setMid, setToken, setTypeOfServices, setuserType } from "../store/Slices/userSlice";
import { LOGIN_API } from "../Utils/Constants";
import axios from "axios";
import { setPrivilagesData, setUserType } from "../store/Slices/privilagesSlice";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import swal from "sweetalert";

const schema = Yup.object().shape({
    password: Yup.string().required("Password is required"),
    userName: Yup.string().required("Username is required"),
  });

export const useLoginForm = () => {
    const [userName, setuserName] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [showPopUp, setShowPopUp] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);
    const navigate = useNavigate();
    const dispatch= useDispatch()
  
    const handleSubmit = async (e) => {
      e.preventDefault();

      if (userName === ""  || password === "") {
        swal({
          title: "ALERT!",
          text: "Please Enter Username & Password!",
          icon: "warning",
        });
        return;
      }
      
  try {
        
        await schema.validate({ password, userName }, { abortEarly: false });
        const response = await axios.post(LOGIN_API, { password, userName });
  
        if (response.data.statusCode === 200) {
          const token = response?.data?.token;
          const {mid} = jwtDecode(token)
         
          const privilages = response?.data?.data?.merchantPrivledgeAndSubpriledge;
          const privilagesData= privilages?.map(item=> item.privilegeSubTypeId)
    
          const data = response?.data?.data;
     
          localStorage.setItem("token", JSON.stringify(token));
          localStorage.setItem("privilagesData", JSON.stringify(privilagesData));
          dispatch(setPrivilagesData(privilagesData))
          dispatch(setToken(token))
          Cookies.set("access_token",token);
          dispatch(setUserType(data?.userType))
          dispatch(setuserType(data?.userType))
          dispatch(setMid(mid))
          dispatch(setIsAuthenticated(true))
          dispatch(setTypeOfServices(JSON.stringify(data?.typeOfServices)))
          localStorage.setItem(
            "typeOfServices",
            JSON.stringify(data?.typeOfServices)
          );
          // localStorage.setItem("paymentType", JSON.stringify(paymentType));
          localStorage.setItem("data", JSON.stringify(data));

          localStorage.setItem("isWithdrawalAcountActive", data?.isWithdrawalAcountActive);
          localStorage.setItem("isWithdrawalPayoutActive", data?.isWithdrawalPayoutActive);
          localStorage.setItem("isPayoutActive", data?.isPayoutActive);

          const stageData = localStorage.getItem("data");
          const stage = stageData ? JSON.parse(stageData) : null;
  
          // console.log(stage && stage.stage === "stage2");
  
          let val = stage && stage.stage2 === "Submitted";
          setShowPopUp(val);
  
          let value = stage && stage.stage === "stage2";
          
  
          if (!val) {
            value ? navigate("/FirstStage") : navigate("/Dashboard");
          }
          // if (stage && stage.stage === "stage2") {
          //   navigate("/FirstStage");
          // }
          // navigate("/Dashboard");
        } else {
          setErrorMessages([response.data.message]);
        }
      } catch (error) {
        if (error.name === "ValidationError") {
          const validationErrors = {};
          error.inner.forEach((err) => {
            validationErrors[err.path] = err.message;
          });
          setErrorMessages(validationErrors);
        } else {
          setError(error.message);
        }
      }
    };
  
    return {
      userName,
      setuserName,
      password,
      setPassword,
      error,
      errorMessages,
      handleSubmit,
      isFormSubmitted,
      showPopUp,
      setShowPopUp,
    };
  };