import { combineReducers, configureStore } from "@reduxjs/toolkit";
 
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import userSlice from "./Slices/userSlice";
import privilagesSlice from "./Slices/privilagesSlice";
import onBoardSlice from "./Slices/onBoardSlice";
import utilSlice from "./Slices/utilSlice";
import reportFilterSlice from "./Slices/reportFilterSlice";
// import reportFilterSlice from "./Slices/reportFilterSlice";
 

// Define slices
const userReducer = userSlice;
const privilagesReducer = privilagesSlice;
const onBoardReducer = onBoardSlice; 
const reportFilterReducer = reportFilterSlice; 
const utilsReducer = utilSlice; 


// Combine reducers
const rootReducer = combineReducers({
    userData: userReducer,
    privilages: privilagesReducer,
    onBoard: onBoardReducer,
    reportFilter: reportFilterReducer,
    utils: utilsReducer
});


const persistConfig = {
  key: "root", 
  storage, 
  whitelist: ["userData", "privilages", "onBoard"]
};


const persistedReducer = persistReducer(persistConfig, rootReducer);


const store = configureStore({
  reducer: persistedReducer,
});

const persistor = persistStore(store);

export { store, persistor };
