import { createSlice } from "@reduxjs/toolkit";

const privilagesSlice = createSlice({
  name: "privilages",
  initialState: {

    privilagesData: [],
    allPrivilagesData:[],
    userType:"",

},
  reducers: {
    setPrivilagesData: (state, action) => {
      state.privilagesData = action.payload;
    },
    setAllPrivilagesData: (state, action) => {
      state.allPrivilagesData = action.payload;
    },
    setUserType: (state, action) => {
      state.userType = action.payload;
    },
    removePrivilagesData: (state) => {
      state.privilagesData = [];
    },
 
  },
});

export const {
    setPrivilagesData,removePrivilagesData,setAllPrivilagesData,setUserType
} = privilagesSlice.actions;

export default privilagesSlice.reducer;
