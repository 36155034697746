import { createSlice } from "@reduxjs/toolkit";

const reportFilterSlice = createSlice({
  name: "report search",
  initialState: {
    filterOn:false,
    dateoption: "",
    otherDateoption: "",
    date: "",
    startDate: "",
    endDate: "",
    otherdate: "",
    otherStartDate: "",
    otherEndDate: "",
    parameterType: "",
    parameterValue: "",
    startAmount: "",
    endAmount: "",
    transectionStatus: "",
    transferType: "",
    reportType: "",
    
  },
  reducers: {
    setDateObject: (state, action) => {
      
      state.dateoption = action.payload.searchOption2;
      state.date = action.payload.searchValue2;
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
    },
    setOtherDateObject: (state, action) => {
      
      state.otherDateoption = action.payload.searchOption2;
      state.otherdate = action.payload.searchValue2;
      state.otherStartDate = action.payload.startDate;
      state.otherEndDate = action.payload.endDate;
    },
    setParameterObject: (state, action) => {
      
      state.parameterValue = action.payload.inputVal;
      state.parameterType = action.payload.searchOption;
      if (action.payload.searchOption === "Amount Range") {
        state.startAmount = action.payload.startAmount;
        state.endAmount = action.payload.endAmount;
      }
    },
    setTransactionStatusObject: (state, action) => {
      state.transectionStatus = action.payload;
    },
    setFilterOn: (state,action) => {
      state.filterOn =  action.payload;
    },
    setReportType: (state, action) => {
      state.reportType = action.payload;
    },
    setTransferTypeObject: (state, action) => {
      state.transferType = action.payload;
    },
    setInitialObject: (state) => {
      state =   {
        dateoption: "",
        otherDateoption: "",
        date: "",
        startDate: "",
        endDate: "",
        otherdate: "",
        otherStartDate: "",
        otherEndDate: "",
        parameterType: "",
        parameterValue: "",
        startAmount: "",
        endAmount: "",
        transectionStatus: "",
        transferType: "",
        reportType: "",
      };
    },
  },
});

export const {
  setDateObject,
  setOtherDateObject,
  setParameterObject,
  setInitialObject,
  setTransactionStatusObject,
  setTransferTypeObject,
  setReportType,
  setFilterOn,
} = reportFilterSlice.actions;

export default reportFilterSlice.reducer;
