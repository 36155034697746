import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "userData",
  initialState: {
    typeOfServices:[],
    type:null,
    isAuthenticated: false,
    token: null,
    Mid: null,
    profileData: null,
    
  },
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setIsAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload ;
    },
    setMid: (state, action) => {
      state.Mid = action.payload;
    },
    setuserType: (state, action) => {
      state.type = action.payload;
    },
    
    setTypeOfServices: (state, action) => {
      state.typeOfServices = action.payload;
    },
    setProfileData: (state, action) => {
      state.profileData = action.payload;
    },
    
  },
});

export const {
    setToken,
    setMid,
    setIsAuthenticated,
    setuserType,
    setTypeOfServices,
    setProfileData
} = userSlice.actions;

export default userSlice.reducer;
