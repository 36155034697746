import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import CopyButton from "../Common/CopyButton/CopyButton";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import MiniLoader from "../Common/MiniLoader/MiniLoader";
import swal from "sweetalert";
import {
  payinActualRequestTableJson,
  payinRequestTableJson,
  payinResponseDescription,
  responseCodes,
} from "../Utils/apiDocs";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dark } from "react-syntax-highlighter/dist/esm/styles/prism";
import styles from "./payin.module.css";
import { FaCircleInfo } from "react-icons/fa6";

import {
  javacode,
  nodejscode,
  pythoncode,
  phpcode,
  rubycode,
  perlcode,
  jscode,
  encjava,
  encnodejs,
  encjs,
  encperl,
  encphp,
  encpython,
  encruby,
} from "../Utils/codeSyntax";
import { SiRubyonrails } from "react-icons/si";
import { GiCamel } from "react-icons/gi";
import { IoLogoJavascript } from "react-icons/io5";
import useApiServices from "../Utils/useApiService";
import { CALLBACK_URL, DECRYPT_CODE } from "../Utils/Constants";


/**
 * This component renders documentation for payin documentation.
 * It displays payin Api Urls , request parameters, responses, etc.
 * @returns {ReactNode} A React element that renders Payin documentation.
 */

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function PayinDocs() {
  const theme = useTheme();
  const { apiCallFnforPost, apiCallFnforPostForOpenUrl } = useApiServices();
  const [codeString, setCodeString] = useState({
    encryptCodeString: encjava,
    dcryptCodeString: javacode,
  });
  const [language, setLanguage] = useState("java");
  const [payindoc, setPayindoc] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({
    amount: "",
    currency: "",
    emailId: "",
    enckey: "",
    mid: "",
    mobileNumber: "",
    orderNo: "",
    respUrl: "",
    txnReqType: "",
    customerVpa: "",
    name: "",
    udf1: "",
    udf2: "",
    udf3: "",
    udf4: "",
    dateOfReg: "",
    udf10: "",
    udf11: "",
    udf12: "",
    udf13: "",
    udf14: "",
    udf5: "",
    udf6: "",
    udf7: "",
    udf8: "",
    udf9: "",
    userId: "",
    userVpa: "",
  });
  const [status, setStatus] = useState({
    code: "",
    value: "",
  });
  const [responseJson, setResponseJson] = useState({
    amount: "",
    currency: "",
    emailId: "",
    enckey: "",
    mid: "",
    mobileNumber: "",
    orderNo: "",
    respUrl: "",
    txnReqType: "",
    name: "",
    customerVpa: "",
    udf1: "",
    udf2: "",
    udf3: "",
    udf4: "",
    dateOfReg: "",
    udf10: "",
    udf11: "",
    udf12: "",
    udf13: "",
    udf14: "",
    udf5: "",
    udf6: "",
    udf7: "",
    udf8: "",
    udf9: "",
    userId: "",
    // userVpa: "",
  });

  const [selectedOption, setSelectedOption] = useState("success");
  const [url, setUrl] = useState("");
  const [cbresponse, setCbResponse] = useState("");
  const [flag, setFlag] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event?.target;
    setPayload((prevPayload) => ({
      ...prevPayload,
      [name]: value,
    }));
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleSelectLanguage = (lang, code, enccode) => {
    setLanguage(lang);
    setCodeString((prev) => ({
      ...prev,
      encryptCodeString: enccode,
      dcryptCodeString: code,
    }));
  };

  const handleEncrptCode = async () => {
    try {
      const err = {};

      if (!payload.customerVpa) {
        err.error =
          "Please enter customerVpa same as in payload request given above";
      } else if (!payload.userId) {
        err.error =
          "Please enter userId same as in payload request given above";
      } else if (!payload.dateOfReg) {
        err.error =
          "Please enter dateOfReg same as in payload request given above";
      }

      if (Object.keys(err).length !== 0) {
        console.log("err object is empty");
        swal({
          title: "Alert!",
          text: err?.error,
          icon: "error",
        });

        return;
      }

      setLoading(true);

      let info = {
        amount: payload.amount,
        currency: payload.currency,
        emailId: payload.txnReqType === "S" ? "" : payload.emailId,
        enckey: payload.enckey,
        mid: payload.mid,
        mobileNumber: payload.txnReqType === "S" ? "" : payload.mobileNumber,
        orderNo: payload.orderNo,
        respUrl: payload.respUrl,
        txnReqType: payload.txnReqType,
        customerVpa: payload.customerVpa,
        name: payload.name,
        udf1: payload.udf1,
        udf2: payload.udf2,
        udf3: payload.udf3,
        udf4: payload.udf4,
        udf5: payload.udf5,
        udf6: payload.udf6,
        udf7: payload.udf7,
        udf8: payload.udf8,
        udf9: payload.udf9,
        udf10: payload.udf10,
        udf11: payload.udf11,
        udf12: payload.udf12,
        udf13: payload.udf13,
        udf14: payload.udf14,
        userId: payload.userId,
        userVpa: payload.userVpa,
        dateOfReg: payload.dateOfReg,
      };

      const resp = await apiCallFnforPostForOpenUrl(DECRYPT_CODE, payload);
      setFlag(true);

      setStatus((prev) => ({
        ...prev,
        code: resp?.statusCode,
        value: resp?.status,
      }));
      setResponseJson(resp?.data !== "" ? resp?.data : resp?.message);
      setLoading(false);
    } catch (error) {
      console.log("error::", error);
      setLoading(false);
    }
  };

  const handleClearData = () => {
    setPayload((prev) => ({
      amount: "",
      currency: "",
      emailId: "",
      enckey: "",
      mid: "",
      mobileNumber: "",
      orderNo: "",
      respUrl: "",
      txnReqType: "",
    }));

    setResponseJson((prev) => ({
      amount: "",
      currency: "",
      emailId: "",
      enckey: "",
      mid: "",
      mobileNumber: "",
      orderNo: "",
      respUrl: "",
      txnReqType: "",
    }));

    setStatus((prev) => ({
      code: "",
      value: "",
    }));
  };

  const handleOptionChange = (e) => {
    const value = e.target.value;
    setSelectedOption(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const urlRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,63}(\/\S*)?$/;
    if (!urlRegex?.test(url)) {
      swal({
        title: "Alert!",
        text: "Please enter url format",
        icon: "error",
      });
      return;
    }

    if (!url) {
      swal({
        title: "Alert!",
        text: "Please enter callack url",
        icon: "error",
      });
      return;
    }

    try {
      let info = {
        callBackUrl: url,
        status: selectedOption,
      };
      const resp = await apiCallFnforPostForOpenUrl(CALLBACK_URL, info);

      setCbResponse(resp?.data);
    } catch (error) {
      console.log("error::", error);
    }
  };

  return (
    <div className="row">
      <div className={`col-xl-8 col-xxl-8 ${styles.leftWrapper}`}>
        <div className="card rounded-1">
          <div className={`card-body p-2 ${styles.leftSubWrapper}`}>
            <div>
              <h5 className="fs-20 fw-bold text-danger">
                Web Based integration is HTML form submit request (this is not
                seamless/ REST API please don't send rest call for this.)
              </h5>
            </div>
            <div class="custom-tab-1">
              <ul class="nav nav-tabs">
                <li class="nav-item" onClick={() => setPayindoc(!payindoc)}>
                  <a class="nav-link active" data-bs-toggle="tab" href="#home1">
                    <i class="la la-home me-2"></i> Web based
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-bs-toggle="tab" href="#profile1">
                    <i class="la la-user me-2"></i> Static / QR
                  </a>
                </li>

                <li onClick={() => setPayindoc(!payindoc)}>
                  <button
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#priviledgeuseredit"
                  >
                    UAT Environment
                  </button>
                </li>
              
              </ul>

              <div
                className="modal fade bd-example-modal-lg"
                tabindex="-1"
                role="dialog"
                aria-modal="true"
                id={"priviledgeuseredit"}
              >
                <div className={`modal-dialog modal-dialog-centered`}>
                  <div class="modal-content">
                    <div className="modal-header bg-primary  rounded-2 ">
                      <h3 className="modal-title">UAT Environment</h3>
                      <button
                        type="button"
                        class="btn-close"
                        // onClick={handleClose}
                        data-bs-dismiss="modal"
                      ></button>
                    </div>
                    <div class="modal-body">
                        <p className="text-black fw-bold ">Encryption Key : <span>8b3a40g766d94345b9d82ad02cfb1df1</span></p>
                        <p className="text-black fw-bold ">MID : <span> IPCOS00024THA</span></p>
                        {/* <p className="text-black fw-bold ">End-Point  : <span>https://api2.lincpay.in/MerchantPanel/paymentRequest/getResponse</span></p> */}
                        <p className="text-black fw-bold ">End-Point  : <span>{DECRYPT_CODE}</span></p>

                    </div>

                    <div class="modal-footer">
                      <button
                        id="close_Btn"
                        type="button"
                        class="btn btn-danger light"
                        data-bs-dismiss="modal"
                        // onClick={handleClose}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-content">
                <div
                  class="tab-pane fade show active"
                  id="home1"
                  role="tabpanel"
                >
                  <div class="pt-4">
                    {/* <h4>Api endpoints</h4> */}
                    <div class="table-responsive">
                      <table class="table table-bordered table-responsive-sm">
                        <thead>
                          <tr>
                            <th>Method</th>
                            <th>Environment</th>
                            <th>Url</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <span class="badge badge-primary">POST</span>{" "}
                            </td>
                            <td class="text-black">Production</td>
                            <td>
                              {" "}
                              <div className="text-black">{`https://pg.icepe.in/paymentrequest`}</div>
                              <div>
                                <CopyButton
                                  text={`https://pg.icepe.in/paymentrequest`}
                                />
                                `
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span class="badge badge-primary">POST</span>{" "}
                            </td>
                            <td class="text-black">Uat / staging</td>
                            <td>
                              {" "}
                              <div className="text-black">{DECRYPT_CODE}</div>
                              <div>
                                <CopyButton text={DECRYPT_CODE} />`
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    {/* <div class="bootstrap-badge d-flex gap-3 align-items-center">
                          <div class="badge badge-warning">POST</div>
                          <div className="text-black">{`https://pg.icepe.in/paymentrequest/seamless`}</div>
                          <div>
                            <CopyButton
                              text={`https://pg.icepe.in/paymentrequest/seamless`}
                            />
                          </div>
                        </div> */}

                    <div className="mt-5">
                      <p className={`mb-2 fs-20 text-black ${styles.font700}`}>
                        Note :-
                      </p>
                      <p className={`mb-2 fs-20 text-black ${styles.font700}`}>
                        For Uat testing :- enckey:
                        8b3a40g766d94345b9d82ad02cfb1df1
                      </p>
                      <h4 className={`mb-2 text-black ${styles.font700}`}>
                        For Uat testing , use this Dummy payload with same
                        values given below
                      </h4>
                    </div>

                    <div className="text-black pt-4">
                      <div className="d-flex gap-4">
                        <h4 className={styles.font700}>Request parameters</h4>
                        <CopyButton
                          text={JSON.stringify(
                            {
                              mid: "1000000000007",

                              enckey: "8b3a40g766d94345b9d82ad02cfb1df1",
                              orderNo: "ORD23062614402635162",

                              amount: "100",

                              currency: "INR",

                              txnReqType: !payindoc ? "S" : "slupi",
                              dateOfReg: "2024-08-07",
                              customerVpa: "1789002584@gbl",
                              name: "naman",
                              userId: "ABCDEF1010",
                              // userVpa: "1234567891@ybl",

                              mobileNo: "9968739673",

                              respUrl: "www.google.com",
                              ["udf1"]: "",
                              ["udf2"]: "",
                              ["udf3"]: "",
                              ["udf4"]: "",
                              ["udf5"]: "",
                              ["udf6"]: "",
                              ["udf7"]: "",
                              ["udf8"]: "",
                              ["udf9"]: "",
                              ["udf10"]: "",
                              ["udf11"]: "",
                              ["udf12"]: "",
                              ["udf13"]: "",
                              ["udf14"]: "",
                            },
                            null,
                            2
                          )}
                        />
                      </div>

                      <pre class="mb-4 post-meta d-flex flex-wrap">
                        {JSON.stringify(
                          {
                            mid: "1000000000007",

                            enckey: "8b3a40g766d94345b9d82ad02cfb1df1",
                            orderNo: "ORD23062614402635162",

                            amount: "100",
                            userId: "ABCDEF1010",
                            // userVpa: "1234567891@ybl",
                            currency: "INR",

                            txnReqType: payindoc ? "S" : "slupi",
                            dateOfReg: "2024-08-07",
                            respUrl: "www.google.com",
                            customerVpa: "1789002584@gbl",
                            name: "naman",
                            emailId: payindoc
                              ? " - "
                              : "ankit.choubey@lincpay.co.in",
                            mobileNo: payindoc ? " - " : "9968739673",
                            ["udf1"]: "",
                            ["udf2"]: "",
                            ["udf3"]: "",
                            ["udf4"]: "",
                            ["udf5"]: "",
                            ["udf6"]: "",
                            ["udf7"]: "",
                            ["udf8"]: "",
                            ["udf9"]: "",
                            ["udf10"]: "",
                            ["udf11"]: "",
                            ["udf12"]: "",
                            ["udf13"]: "",
                            ["udf14"]: "",
                          },
                          null,
                          2
                        )}
                      </pre>
                    </div>

                    <div class="card-body">
                      <h5 className={`text-black ${styles.font700}`}>
                        BODY PARAMS:
                      </h5>
                      <div class="basic-list-group">
                        <ul class="list-group">
                          <li class="list-group-item p-3">
                            <div class="row d-flex justify-content-between align-items-center">
                              <label class="col-sm-7 col-form-label col-form-label-md text-black">
                                amount
                              </label>
                              <div class="col-sm-5">
                                <input
                                  name="amount"
                                  type="number"
                                  value={payload.amount}
                                  className={`form-control form-control-sm text-black ${styles.inputStyle}`}
                                  placeholder="000"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </li>

                          <li class="list-group-item p-3">
                            <div class="row d-flex justify-content-between align-items-center">
                              <label class="col-sm-7 col-form-label col-form-label-md text-black">
                                currency
                              </label>
                              <div class="col-sm-5">
                                <input
                                  name="currency"
                                  type="text"
                                  value={payload.currency}
                                  className={`form-control form-control-sm text-black ${styles.inputStyle}`}
                                  placeholder="INR"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </li>

                          <li class="list-group-item p-3">
                            <div class="row d-flex justify-content-between align-items-center">
                              <label class="col-sm-7 col-form-label col-form-label-md text-black">
                                emailId
                              </label>
                              <div class="col-sm-5">
                                <input
                                  name="emailId"
                                  type="email"
                                  value={payload.emailId}
                                  className={`form-control form-control-sm text-black ${styles.inputStyle}`}
                                  placeholder="test@gmail.com"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </li>
                          <li class="list-group-item p-3">
                            <div class="row d-flex justify-content-between align-items-center">
                              <label class="col-sm-7 col-form-label col-form-label-md text-black">
                                UserId
                              </label>
                              <div class="col-sm-5">
                                <input
                                  name="userId"
                                  type="text"
                                  value={payload.userId}
                                  className={`form-control form-control-sm text-black ${styles.inputStyle}`}
                                  placeholder="ABCDEF1010"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </li>
                          {/* <li class="list-group-item p-3">
                                <div class="row d-flex justify-content-between align-items-center">
                                  <label class="col-sm-7 col-form-label col-form-label-md text-black">
                                    UserVpa
                                  </label>
                                  <div class="col-sm-5">
                                    <input
                                      name="userVpa"
                                      type="text"
                                      value={payload.userVpa}
                                      className={`form-control form-control-sm text-black ${styles.inputStyle}`}
                                      placeholder="1234567891@ybl"
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>
                              </li> */}
                          <li class="list-group-item p-3">
                            <div class="row d-flex justify-content-between align-items-center">
                              <label class="col-sm-7 col-form-label col-form-label-md text-black">
                                CustomerVpa
                              </label>
                              <div class="col-sm-5">
                                <input
                                  name="customerVpa"
                                  type="text"
                                  value={payload.customerVpa}
                                  className={`form-control form-control-sm text-black ${styles.inputStyle}`}
                                  placeholder="1789052486@ybl"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </li>
                          <li class="list-group-item p-3">
                            <div class="row d-flex justify-content-between align-items-center">
                              <label class="col-sm-7 col-form-label col-form-label-md text-black">
                                DateOfReg
                              </label>
                              <div class="col-sm-5">
                                <input
                                  name="dateOfReg"
                                  type="date"
                                  value={payload.dateOfReg}
                                  className={`form-control form-control-sm text-black ${styles.inputStyle}`}
                                  placeholder="2024-08-07"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </li>

                          <li class="list-group-item p-3">
                            <div class="row d-flex justify-content-between align-items-center">
                              <label class="col-sm-7 col-form-label col-form-label-md text-black">
                                enckey
                              </label>
                              <div class="col-sm-5">
                                <input
                                  name="enckey"
                                  type="text"
                                  value={payload.enckey}
                                  className={`form-control form-control-sm text-black ${styles.inputStyle}`}
                                  placeholder="8b3a40g766d94345b9d82ad02cfb1df1"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </li>

                          <li class="list-group-item p-3">
                            <div class="row d-flex justify-content-between align-items-center">
                              <label class="col-sm-7 col-form-label col-form-label-md text-black">
                                mid
                              </label>
                              <div class="col-sm-5">
                                <input
                                  name="mid"
                                  type="text"
                                  value={payload.mid}
                                  className={`form-control form-control-sm text-black ${styles.inputStyle}`}
                                  placeholder="1000000000007"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </li>

                          <li class="list-group-item p-3">
                            <div class="row d-flex justify-content-between align-items-center">
                              <label class="col-sm-7 col-form-label col-form-label-md text-black">
                                mobileNumber
                              </label>
                              <div class="col-sm-5">
                                <input
                                  name="mobileNumber"
                                  type="number"
                                  value={payload.mobileNumber}
                                  className={`form-control form-control-sm text-black ${styles.inputStyle}`}
                                  placeholder="9968739673"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </li>

                          <li class="list-group-item p-3">
                            <div class="row d-flex justify-content-between align-items-center">
                              <label class="col-sm-7 col-form-label col-form-label-md text-black">
                                orderNo
                              </label>
                              <div class="col-sm-5">
                                <input
                                  name="orderNo"
                                  type="text"
                                  value={payload.orderNo}
                                  className={`form-control form-control-sm text-black ${styles.inputStyle}`}
                                  placeholder="ORD23062614402635162"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </li>

                          <li class="list-group-item p-3">
                            <div class="row d-flex justify-content-between align-items-center">
                              <label class="col-sm-7 col-form-label col-form-label-md text-black">
                                respUrl
                              </label>
                              <div class="col-sm-5">
                                <input
                                  name="respUrl"
                                  type="text"
                                  value={payload.respUrl}
                                  className={`form-control form-control-sm text-black ${styles.inputStyle}`}
                                  placeholder="www.google.com"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </li>

                          <li class="list-group-item p-3">
                            <div class="row d-flex justify-content-between align-items-center">
                              <label class="col-sm-7 col-form-label col-form-label-md text-black">
                                txnReqType
                              </label>
                              <div class="col-sm-5">
                                <input
                                  name="txnReqType"
                                  type="text"
                                  value={payload.txnReqType}
                                  className={`form-control form-control-sm text-black ${styles.inputStyle}`}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </li>

                          <li class="list-group-item p-3">
                            <div class="row d-flex justify-content-between align-items-center">
                              <label class="col-sm-7 col-form-label col-form-label-md text-black">
                                name
                              </label>
                              <div class="col-sm-5">
                                <input
                                  name="name"
                                  type="text"
                                  value={payload.name}
                                  className={`form-control form-control-sm text-black ${styles.inputStyle}`}
                                  placeholder="www.google.com"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </li>

                          <li class="list-group-item p-3">
                            <div class="row d-flex justify-content-between align-items-center">
                              <label class="col-sm-7 col-form-label col-form-label-md text-black">
                                udf1
                              </label>
                              <div class="col-sm-5">
                                <input
                                  name="udf1"
                                  type="text"
                                  value={payload?.udf1}
                                  className={`form-control form-control-sm text-black ${styles.inputStyle}`}
                                  placeholder="additional data"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </li>

                          <li class="list-group-item p-3">
                            <div class="row d-flex justify-content-between align-items-center">
                              <label class="col-sm-7 col-form-label col-form-label-md text-black">
                                udf2
                              </label>
                              <div class="col-sm-5">
                                <input
                                  name="udf2"
                                  type="text"
                                  value={payload?.udf2}
                                  className={`form-control form-control-sm text-black ${styles.inputStyle}`}
                                  placeholder="additional data"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </li>

                          <li class="list-group-item p-3">
                            <div class="row d-flex justify-content-between align-items-center">
                              <label class="col-sm-7 col-form-label col-form-label-md text-black">
                                udf3
                              </label>
                              <div class="col-sm-5">
                                <input
                                  name="udf3"
                                  type="text"
                                  value={payload?.udf3}
                                  className={`form-control form-control-sm text-black ${styles.inputStyle}`}
                                  placeholder="additional data"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </li>

                          <li class="list-group-item p-3">
                            <div class="row d-flex justify-content-between align-items-center">
                              <label class="col-sm-7 col-form-label col-form-label-md text-black">
                                udf4
                              </label>
                              <div class="col-sm-5">
                                <input
                                  name="udf4"
                                  type="text"
                                  value={payload?.udf4}
                                  className={`form-control form-control-sm text-black ${styles.inputStyle}`}
                                  placeholder="additional data"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </li>
                          <li class="list-group-item p-3">
                            <div class="row d-flex justify-content-between align-items-center">
                              <label class="col-sm-7 col-form-label col-form-label-md text-black">
                                udf5
                              </label>
                              <div class="col-sm-5">
                                <input
                                  name="udf5"
                                  type="text"
                                  value={payload?.udf5}
                                  className={`form-control form-control-sm text-black ${styles.inputStyle}`}
                                  placeholder="additional data"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </li>
                          <li class="list-group-item p-3">
                            <div class="row d-flex justify-content-between align-items-center">
                              <label class="col-sm-7 col-form-label col-form-label-md text-black">
                                udf6
                              </label>
                              <div class="col-sm-5">
                                <input
                                  name="udf6"
                                  type="text"
                                  value={payload?.udf6}
                                  className={`form-control form-control-sm text-black ${styles.inputStyle}`}
                                  placeholder="additional data"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </li>
                          <li class="list-group-item p-3">
                            <div class="row d-flex justify-content-between align-items-center">
                              <label class="col-sm-7 col-form-label col-form-label-md text-black">
                                udf7
                              </label>
                              <div class="col-sm-5">
                                <input
                                  name="udf7"
                                  type="text"
                                  value={payload?.udf7}
                                  className={`form-control form-control-sm text-black ${styles.inputStyle}`}
                                  placeholder="additional data"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </li>
                          <li class="list-group-item p-3">
                            <div class="row d-flex justify-content-between align-items-center">
                              <label class="col-sm-7 col-form-label col-form-label-md text-black">
                                udf8
                              </label>
                              <div class="col-sm-5">
                                <input
                                  name="udf8"
                                  type="text"
                                  value={payload?.udf8}
                                  className={`form-control form-control-sm text-black ${styles.inputStyle}`}
                                  placeholder="additional data"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </li>
                          <li class="list-group-item p-3">
                            <div class="row d-flex justify-content-between align-items-center">
                              <label class="col-sm-7 col-form-label col-form-label-md text-black">
                                udf9
                              </label>
                              <div class="col-sm-5">
                                <input
                                  name="udf9"
                                  type="text"
                                  value={payload?.udf9}
                                  className={`form-control form-control-sm text-black ${styles.inputStyle}`}
                                  placeholder="additional data"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </li>
                          <li class="list-group-item p-3">
                            <div class="row d-flex justify-content-between align-items-center">
                              <label class="col-sm-7 col-form-label col-form-label-md text-black">
                                udf10
                              </label>
                              <div class="col-sm-5">
                                <input
                                  name="udf10"
                                  type="text"
                                  value={payload?.udf10}
                                  className={`form-control form-control-sm text-black ${styles.inputStyle}`}
                                  placeholder="additional data"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </li>
                          <li class="list-group-item p-3">
                            <div class="row d-flex justify-content-between align-items-center">
                              <label class="col-sm-7 col-form-label col-form-label-md text-black">
                                udf11
                              </label>
                              <div class="col-sm-5">
                                <input
                                  name="udf11"
                                  type="text"
                                  value={payload?.udf11}
                                  className={`form-control form-control-sm text-black ${styles.inputStyle}`}
                                  placeholder="additional data"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </li>
                          <li class="list-group-item p-3">
                            <div class="row d-flex justify-content-between align-items-center">
                              <label class="col-sm-7 col-form-label col-form-label-md text-black">
                                udf12
                              </label>
                              <div class="col-sm-5">
                                <input
                                  name="udf12"
                                  type="text"
                                  value={payload?.udf12}
                                  className={`form-control form-control-sm text-black ${styles.inputStyle}`}
                                  placeholder="additional data"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </li>
                          <li class="list-group-item p-3">
                            <div class="row d-flex justify-content-between align-items-center">
                              <label class="col-sm-7 col-form-label col-form-label-md text-black">
                                udf13
                              </label>
                              <div class="col-sm-5">
                                <input
                                  name="udf13"
                                  type="text"
                                  value={payload?.udf13}
                                  className={`form-control form-control-sm text-black ${styles.inputStyle}`}
                                  placeholder="additional data"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </li>
                          <li class="list-group-item p-3">
                            <div class="row d-flex justify-content-between align-items-center">
                              <label class="col-sm-7 col-form-label col-form-label-md text-black">
                                udf14
                              </label>
                              <div class="col-sm-5">
                                <input
                                  name="udf14"
                                  type="text"
                                  value={payload?.udf14}
                                  className={`form-control form-control-sm text-black ${styles.inputStyle}`}
                                  placeholder="additional data"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <h4 className={`mb-2 text-black ${styles.font700}`}>
                      Request paramenter description :
                    </h4>

                    <div class="table-responsive">
                      <table class="table header-border table-responsive-sm border">
                        <thead>
                          <tr>
                            <th style={{ fontWeight: "700" }}>Key</th>
                            <th style={{ fontWeight: "700" }}>
                              Type / Mandatory
                            </th>
                            <th style={{ fontWeight: "700" }}>Length</th>
                            <th style={{ fontWeight: "700" }}>Description</th>
                            <th style={{ fontWeight: "700" }}>
                              Possible values/Example
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {payinRequestTableJson.fields.map((item, index) => {
                            return (
                              <tr key={index} className={styles.colorblack}>
                                <th>{item?.Key}</th>
                                <td>{item?.["Type / Mandatory"]}</td>
                                <td>{item?.length}</td>
                                <td>
                                  {item?.Key == "txnReqType"
                                    ? // <div>
                                      //   <FaCircleInfo
                                      //     fontSize={20}
                                      //     onClick={handleClick}
                                      //   />

                                      //   <Popover
                                      //     id={id}
                                      //     open={open}
                                      //     anchorEl={anchorEl}
                                      //     onClose={handleClose}
                                      //     anchorOrigin={{
                                      //       vertical: "bottom",
                                      //       horizontal: "left",
                                      //     }}
                                      //   >
                                      //     {/* <Typography sx={{ p: 2 }}> */}
                                      //     <div class="table-responsive">
                                      //       <table class="table table-responsive-sm">
                                      //         <tbody className="border">
                                      //           <tr>
                                      //             <th class="text-black">
                                      //               Web Based
                                      //             </th>

                                      //             <td>
                                      //               <span class="badge badge-primary">
                                      //                 S
                                      //               </span>
                                      //             </td>
                                      //             <td>Capital letter</td>
                                      //           </tr>
                                      //           <tr>
                                      //             <th class="text-black">
                                      //               Seamless / intent
                                      //             </th>

                                      //             <td>
                                      //               <span class="badge badge-success">
                                      //                 slupi
                                      //               </span>
                                      //             </td>
                                      //             <td>Small letter</td>
                                      //           </tr>
                                      //         </tbody>
                                      //       </table>
                                      //     </div>
                                      //     {/* </Typography> */}
                                      //   </Popover>
                                      // </div>
                                      ""
                                    : item?.Description}
                                </td>
                                <td>{item?.["Possible values/Example"]}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>

                    <div className="d-flex gap-4 mt-5">
                      <h4 class="mb-2" className={styles.font700}>
                        ActualRequest:
                      </h4>
                      <CopyButton
                        text={JSON.stringify(
                          {
                            mid: "1000000000007",

                            payload:
                              "TM5w6ln/ueqx/5C8p8yybcsdBo+6L6b5oUW3jTK7k1l8++SKynsBDKWKEIjskpFonlbDgCt29AvqawbsHLdq4eLoBb0jbaog7lMbhDfETnVc/AR/68Gnob0dp4dzbMm7jgIIHDNKP9z7Jbef9Pwr36bNhMCrGPYKIcHnVj7BZZcbz713DDbz9Tu9dJTcxvAPSgqIwCGuUkPGWDy4QLo2Q/S5p/5nOV7Hvp0D+pCSp+xrRixGp8M37X9mCPOtW2sHBbJ8OTYT/Wrmx8gHYskDlgURAXyASlfbYgPAPPhHfHvzBRRHOj+zLfu6qmKDkx/j1lj2FfMrzv0LngGcfm9Afzk5ktKdodskEark8TXCXREKf3Gv1KXbNNtXrwORf/EHHDOkLulZjP8qzCPKaTAiHg==",
                          },
                          null,
                          2
                        )}
                      />
                    </div>

                    <pre class="mb-4 text-black post-meta d-flex flex-wrap">
                      {JSON.stringify(
                        {
                          mid: "1000000000007",

                          payload:
                            "TM5w6ln/ueqx/5C8p8yybcsdBo+6L6b5oUW3jTK7k1l8++SKynsBDKWKEIjskpFonlbDgCt29AvqawbsHLdq4eLoBb0jbaog7lMbhDfETnVc/AR/68Gnob0dp4dzbMm7jgIIHDNKP9z7Jbef9Pwr36bNhMCrGPYKIcHnVj7BZZcbz713DDbz9Tu9dJTcxvAPSgqIwCGuUkPGWDy4QLo2Q/S5p/5nOV7Hvp0D+pCSp+xrRixGp8M37X9mCPOtW2sHBbJ8OTYT/Wrmx8gHYskDlgURAXyASlfbYgPAPPhHfHvzBRRHOj+zLfu6qmKDkx/j1lj2FfMrzv0LngGcfm9Afzk5ktKdodskEark8TXCXREKf3Gv1KXbNNtXrwORf/EHHDOkLulZjP8qzCPKaTAiHg==",
                        },
                        null,
                        2
                      )}
                    </pre>

                    <h4 class="mb-2 text-black" className={styles.font700}>
                      Actual request description :
                    </h4>

                    <div class="table-responsive">
                      <table class="table header-border table-responsive-sm">
                        <thead>
                          <tr>
                            <th style={{ fontWeight: "700" }}>Key</th>
                            <th style={{ fontWeight: "700" }}>
                              Type / Mandatory
                            </th>
                            <th style={{ fontWeight: "700" }}>Description</th>
                            <th style={{ fontWeight: "700" }}>
                              Possible values/Example
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {payinActualRequestTableJson.fields.map(
                            (item, index) => {
                              return (
                                <tr key={index} className={styles.colorblack}>
                                  <th>{item?.Key}</th>
                                  <td>{item?.["Type / Mandatory"]}</td>
                                  <td>{item?.Description}</td>
                                  <td>{item?.["Possible values/Example"]}</td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </table>
                    </div>

                    <div className="text-black pt-4">
                      <div className="col-xl-12">
                        <h4 className={`text-black ${styles.font700}`}>
                          Callback url
                        </h4>

                        <div class="basic-form ">
                          <form>
                            <div class="mb-3  mb-0">
                              <div class="radio">
                                <label>
                                  <input
                                    type="radio"
                                    id="success1"
                                    value="success"
                                    checked={selectedOption == "success"}
                                    onChange={handleOptionChange}
                                  />{" "}
                                  Success
                                </label>
                              </div>
                              <div class="radio">
                                <label>
                                  <input
                                    type="radio"
                                    id="failed1"
                                    value="failed"
                                    checked={selectedOption == "failed"}
                                    onChange={handleOptionChange}
                                  />{" "}
                                  Failed
                                </label>
                              </div>
                            </div>

                            <div class="mb-3 d-flex align-items-center gap-2 col-lg-8">
                              <input
                                class="form-control form-control-sm "
                                type="text"
                                placeholder="callback url"
                                name="callbackurl"
                                value={url}
                                required
                                onChange={(e) => setUrl(e.target.value)}
                              />
                              <a
                                onClick={handleSubmit}
                                type="button"
                                class="btn btn-primary"
                              >
                                submit
                              </a>

                              <a
                                onClick={() => {
                                  setUrl("");
                                  setCbResponse("");
                                }}
                                type="button"
                                class="btn btn-primary"
                              >
                                clear
                              </a>
                            </div>
                          </form>
                        </div>
                      </div>

                      <div>
                        <SyntaxHighlighter style={dark} language="javascript">
                          {cbresponse}
                        </SyntaxHighlighter>
                      </div>
                    </div>

                    <div class="card-body mt-4 text-black">
                      <div class="table-responsive">
                        <table class="table table-responsive-md">
                          <thead>
                            <tr>
                              <th>
                                <strong>Response message</strong>
                              </th>
                              <th>
                                <strong>Response code</strong>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="text-black">
                            <tr>
                              <td>Sucess</td>
                              <td>200</td>
                            </tr>
                            <tr>
                              <td>fail/Exception</td>
                              <td>417</td>
                            </tr>
                            <tr>
                              <td>Transaction processing</td>
                              <td>198</td>
                            </tr>
                            <tr>
                              <td>Rejected by acquirer</td>
                              <td>197</td>
                            </tr>
                            <tr>
                              <td>failed</td>
                              <td>99</td>
                            </tr>
                            <tr>
                              <td>Declined</td>
                              <td>194</td>
                            </tr>
                            <tr>
                              <td>
                                Authorization success but error processing
                                recurring payment
                              </td>
                              <td>193</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="text-black pt-4">
                      <div class="col-xl-12">
                        <div class="card">
                          <div class="card-header col-xl-12  pl-3">
                            <div
                              onClick={() =>
                                handleSelectLanguage("java", javacode, encjava)
                              }
                              className={
                                language == "java"
                                  ? styles.active
                                  : styles.inactive
                              }
                            >
                              <i
                                className={`fa-brands fa-java ${styles.fonthalfrem}`}
                              ></i>
                              <p>Java</p>
                            </div>

                            <div
                              onClick={() =>
                                handleSelectLanguage(
                                  "node",
                                  nodejscode,
                                  encnodejs
                                )
                              }
                              className={
                                language == "node"
                                  ? styles.active
                                  : styles.inactive
                              }
                            >
                              <i
                                className={`fa-brands fa-node ${styles.fonthalfrem}`}
                              ></i>
                              <p>Nodejs</p>
                            </div>
                            <div
                              onClick={() =>
                                handleSelectLanguage(
                                  "python",
                                  pythoncode,
                                  encpython
                                )
                              }
                              className={
                                language == "python"
                                  ? styles.active
                                  : styles.inactive
                              }
                            >
                              <i
                                className={`fa-brands fa-python ${styles.fonthalfrem}`}
                              ></i>
                              <p>Python</p>
                            </div>
                            <div
                              onClick={() =>
                                handleSelectLanguage("php", phpcode, encphp)
                              }
                              className={
                                language == "php"
                                  ? styles.active
                                  : styles.inactive
                              }
                            >
                              <i
                                className={`fa-brands fa-php ${styles.fonthalfrem}`}
                              ></i>
                              <p>PHP</p>
                            </div>
                            <div
                              onClick={() =>
                                handleSelectLanguage("ruby", rubycode, encruby)
                              }
                              className={
                                language == "ruby"
                                  ? styles.active
                                  : styles.inactive
                              }
                            >
                              <SiRubyonrails className={styles.fonthalfrem} />
                              <p>Ruby</p>
                            </div>
                            <div
                              onClick={() =>
                                handleSelectLanguage("perl", perlcode, encperl)
                              }
                              className={
                                language == "perl"
                                  ? styles.active
                                  : styles.inactive
                              }
                            >
                              <GiCamel className={styles.fonthalfrem} />
                              <p>Perl</p>
                            </div>
                            <div
                              onClick={() =>
                                handleSelectLanguage(
                                  "javascript",
                                  jscode,
                                  encjs
                                )
                              }
                              className={
                                language == "javascript"
                                  ? styles.active
                                  : styles.inactive
                              }
                            >
                              <IoLogoJavascript
                                className={styles.fonthalfrem}
                              />
                              <p>Javascript</p>
                            </div>
                          </div>

                          <div className="text-black pt-4">
                            <div className="d-flex gap-4">
                              <h4
                                class="mb-2"
                                className={`mb-2 ${styles.font700}`}
                              >
                                {`Encryption Algorithm (${language}):`}
                              </h4>
                            </div>

                            <div className={styles.position_relative}>
                              <div
                                // className={styles.position_absolute}
                                style={{
                                  position: "absolute",
                                  right: 10,
                                  top: "2%",
                                }}
                              >
                                <CopyButton
                                  text={codeString?.encryptCodeString?.trim()}
                                  color={"white"}
                                />
                              </div>
                              <SyntaxHighlighter language="java" style={dark}>
                                {codeString?.encryptCodeString?.trim()}
                              </SyntaxHighlighter>
                            </div>
                          </div>

                          <div className="text-black pt-4">
                            <h4 className={`mb-2 ${styles.font700}`}>
                              Descryption:
                            </h4>
                            <div class="card-body">
                              <div className={styles.position_relative}>
                                <div
                                  style={{
                                    position: "absolute",
                                    right: 10,
                                    top: "1%",
                                  }}
                                >
                                  <CopyButton
                                    text={codeString?.dcryptCodeString?.trim()}
                                    color={"white"}
                                  />
                                </div>
                                <SyntaxHighlighter
                                  language="javascript"
                                  style={dark}
                                >
                                  {codeString?.dcryptCodeString?.trim()}
                                </SyntaxHighlighter>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="text-black pt-4">
                      <div className="d-flex gap-4">
                        <h4 className={styles.font700}>Response json</h4>
                        <CopyButton
                          text={JSON.stringify(
                            {
                              message: "SUCCESS",
                              statusCode: "OK",
                              responseData: {
                                amount: "100",

                                orderNo: "ORD23091114541117906",

                                created_on: "MonSep11 14:54:31IST2023",
                                qrString:
                                  "upi://pay?ver=01&mode=15&am=100.00&mam=100.00&cu=INR&pa=XXXXXXXXXXXXXXXXXXX&pn=XXXXXXXXXXXXXXXX&mc=8299&tr=Zenex52149242658716179885&tn=Pay to Merchant&mid=ZENEX001&msid=ZENEX001- ANUBHAV&mtid=ZENEX001-001",

                                mid: "SWIPEPROD100007",
                                txnId: "52149242658716179885",
                                userId: "ABCDEF1010",
                                userVpa: "1234567891@ybl",
                                dateOfReg: "2024-08-07",
                                customerVpa: "1245789638@gbl",
                              },
                            },
                            null,
                            2
                          )}
                        />
                      </div>

                      <pre class="mb-4 post-meta d-flex flex-wrap">
                        {JSON.stringify(
                          {
                            message: "SUCCESS",
                            statusCode: "OK",
                            responseData: {
                              amount: "100",

                              orderNo: "ORD23091114541117906",

                              created_on: "MonSep11 14:54:31IST2023",
                              qrString:
                                "upi://pay?ver=01&mode=15&am=100.00&mam=100.00&cu=INR&pa=XXXXXXXXXXXXXXXXXXX&pn=XXXXXXXXXXXXXXXX&mc=8299&tr=Zenex52149242658716179885&tn=Pay to Merchant&mid=ZENEX001&msid=ZENEX001- ANUBHAV&mtid=ZENEX001-001",

                              mid: "SWIPEPROD100007",
                              txnId: "52149242658716179885",
                              userId: "ABCDEF1010",
                              userVpa: "1234567891@ybl",
                              dateOfReg: "2024-08-07",
                              customerVpa: "1245789638@gbl",
                            },
                          },
                          null,
                          2
                        )}
                      </pre>
                    </div>

                    <h4 class="mb-2 text-black" className={styles.font700}>
                      Response description :
                    </h4>

                    <div class="table-responsive">
                      <table class="table header-border table-responsive-sm">
                        <thead>
                          <tr>
                            <th style={{ fontWeight: "700" }}>Key</th>
                            <th style={{ fontWeight: "700" }}>
                              Type / Mandatory
                            </th>
                            <th style={{ fontWeight: "700" }}>Description</th>
                            <th style={{ fontWeight: "700" }}>
                              Possible values/Example
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {payinResponseDescription.fields.map(
                            (item, index) => {
                              return (
                                <tr key={index} className={styles.colorblack}>
                                  <th>{item?.Key}</th>
                                  <td>{item?.["Type / Mandatory"]}</td>
                                  <td>{item?.Description}</td>
                                  <td>{item?.["Possible values/Example"]}</td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </table>
                    </div>

                    <div className="text-black pt-4">
                      <div className="d-flex gap-4">
                        <h4 className={styles.font700}>Callback:</h4>
                        <CopyButton
                          text={JSON.stringify(
                            {
                              mid: "1000000000007",

                              data: "data will be encrypted string, merchant will need to decrypt the data using above mentioned encryption alogrithm",

                              signature: " XXXXXXXXXXXXXXX ",
                            },
                            null,
                            2
                          )}
                        />
                      </div>
                      <h5 class="mb-2">
                        Merchant needs to provide callback endpoint where
                        payment engine will provide callback response with below
                        mention parameter. CallbackURL: Provided by merchant
                        Data sent in callback:
                      </h5>
                      <pre class="mb-4 post-meta d-flex flex-wrap">
                        {JSON.stringify(
                          {
                            mid: "1000000000007",

                            data: "data will be encrypted string, merchant will need to decrypt the data using above mentioned encryption alogrithm",

                            signature: " XXXXXXXXXXXXXXX ",
                          },
                          null,
                          2
                        )}
                      </pre>
                    </div>

                    <div className="text-black pt-4">
                      <div className="d-flex gap-4">
                        <h4 className={styles.font700}>Checksum / Signature</h4>
                      </div>
                      <h5 class="mb-2">
                        Signature is check sum value that is generate to check
                        the data integrity.For generate checksum/signature
                        please use response field at given sequence-
                      </h5>

                      <h6>Mid + txnId + orderNo + txnStatus</h6>

                      <h6>
                        Kindly decrypt the data and recalculate the signature
                        using above mentioned 4 parameter and compare both the
                        signature.
                      </h6>

                      <h5>Signature/checksum generation algorithm:</h5>
                      <h6>
                        Prepare data and call get ChecksumCRC32 method to
                        generate the checksum :
                      </h6>

                      <div className={styles.position_relative}>
                        <div
                          style={{
                            position: "absolute",
                            right: 10,
                            top: "2%",
                          }}
                        >
                          <CopyButton
                            text={`
                                      String checksum Str = transaction.getMid() + transaction .getTxnId()transaction .getOrderNo() + transaction.getTxnStatus();
                                                        
                                      Input Stream target Stream  =  new Byte Array Input Stream( checksum Str .getBytes());
                       
                                      checkSum = get ChecksumCRC32 (target Stream, checksum Str.length());
                                                   `}
                            color={"white"}
                          />
                        </div>
                        <SyntaxHighlighter language="java" style={dark}>
                          {`
               String checksum Str = transaction.getMid() + transaction .getTxnId()transaction .getOrderNo() + transaction.getTxnStatus();
                                 
               Input Stream target Stream  =  new Byte Array Input Stream( checksum Str .getBytes());

               checkSum = get ChecksumCRC32 (target Stream, checksum Str.length());
                            `}
                        </SyntaxHighlighter>
                      </div>

                      <pre class="mb-4 post-meta d-flex flex-wrap">
                        {JSON.stringify(
                          {
                            mid: "1000000000007",

                            data: "data will be encrypted string, merchant will need to decrypt the data using above mentioned encryption alogrithm",

                            signature: " XXXXXXXXXXXXXXX ",
                          },
                          null,
                          2
                        )}
                      </pre>
                    </div>

                    <div className="text-black pt-2">
                      <h5 style={{ fontWeight: "600" }}>Algorithm</h5>

                      <div className={styles.position_relative}>
                        <div
                          style={{
                            position: "absolute",
                            right: 10,
                            top: "2%",
                          }}
                        >
                          <CopyButton
                            text={`public static long getChecksumCRC32(InputStream stream, int bufferSize) throws IOException {
                                        CheckedInputStream checkedInputStream = new CheckedInputStream(stream, new CRC32());
                                        byte[] buffer = new byte[bufferSize];
                                        while (checkedInputStream.read(buffer, 0, buffer.length) >= 0) {
                                            // Do something with the buffer if needed
                                        }
                                        LOGGER.info("Checksum:" + checkedInputStream.getChecksum().getValue());
                                        return checkedInputStream.getChecksum().getValue();
                                    }`}
                            color={"white"}
                          />
                        </div>
                        <SyntaxHighlighter language="java" style={dark}>
                          {`public static long getChecksumCRC32(InputStream stream, int bufferSize) throws IOException {
                                        CheckedInputStream checkedInputStream = new CheckedInputStream(stream, new CRC32());
                                        byte[] buffer = new byte[bufferSize];
                                        while (checkedInputStream.read(buffer, 0, buffer.length) >= 0) {
                                            // Do something with the buffer if needed
                                        }
                                        LOGGER.info("Checksum:" + checkedInputStream.getChecksum().getValue());
                                        return checkedInputStream.getChecksum().getValue();
                                    }`}
                        </SyntaxHighlighter>
                      </div>
                    </div>

                    <div className="text-black mt-5">
                      <h5 className={styles.font700}>
                        Expected Response Json from merchant (Without Any
                        Warning Message)
                      </h5>
                      <pre>
                        {JSON.stringify(
                          {
                            status: "205",
                          },
                          null,
                          2
                        )}
                      </pre>

                      <h5 className={styles.font700}>
                        Expected Fraud Acknowledgement Response Json from
                        merchant If Payment Accepted By Merchant
                      </h5>
                      <pre>
                        {JSON.stringify(
                          {
                            status: "206",
                          },
                          null,
                          2
                        )}
                      </pre>

                      <h5 className={styles.font700}>
                        Expected Fraud Acknowledgement Response Json from
                        merchant If Payment Rejected By Merchant
                      </h5>
                      <pre>
                        {JSON.stringify(
                          {
                            status: "207",
                          },
                          null,
                          2
                        )}
                      </pre>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="profile1">
                  <div class="pt-4"></div>
                </div>
                <div class="tab-pane fade" id="contact1">
                  <div class="pt-4">
                    <h4>This is contact title</h4>
                    <p>
                      Far far away, behind the word mountains, far from the
                      countries Vokalia and Consonantia, there live the blind
                      texts. Separated they live in Bookmarksgrove.
                    </p>
                    <p>
                      Far far away, behind the word mountains, far from the
                      countries Vokalia and Consonantia, there live the blind
                      texts. Separated they live in Bookmarksgrove.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`col-xl-4 col-xxl-4  ${styles.rightWrapper}`}>
        <div className="card rounded-1">
          <div className="card-body p-2">
            <div className={styles.position_relative}>
              <div
                className="d-flex gap-2 align-items-center"
                style={{
                  position: "absolute",
                  bottom: "5%",
                  right: "5%",
                }}
              >
                <button onClick={handleClearData} className={styles.buttons}>
                  clear
                </button>
                <button onClick={handleEncrptCode} className={styles.buttons}>
                  {loading ? <MiniLoader /> : "Try it"}
                </button>
              </div>

              <SyntaxHighlighter
                style={dark}
                language="javascript"
                showLineNumbers
              >
                {JSON.stringify(
                  {
                    amount: payload.amount,
                    currency: payload.currency,
                    dateOfReg: payload.dateOfReg,
                    emailId: payload.emailId,
                    enckey: payload.enckey,
                    userId: payload.userId,
                    // userVpa: payload.userVpa,
                    customerVpa: payload.customerVpa,
                    mid: payload.mid,
                    mobileNumber: payload.mobileNumber,
                    name: payload.name,
                    orderNo: payload.orderNo,
                    respUrl: payload.respUrl,
                    txnReqType: payload.txnReqType,
                    ["udf1"]: payload.udf1,
                    ["udf2"]: payload.udf2,
                    ["udf3"]: payload.udf3,
                    ["udf4"]: payload.udf4,
                    ["udf5"]: payload.udf5,
                    ["udf6"]: payload.udf6,
                    ["udf7"]: payload.udf7,
                    ["udf8"]: payload.udf8,
                    ["udf9"]: payload.udf9,
                    ["udf10"]: payload.udf10,
                    ["udf11"]: payload.udf11,
                    ["udf12"]: payload.udf12,
                    ["udf13"]: payload.udf13,
                    ["udf14"]: payload.udf14,
                  },
                  null,
                  2
                )}
              </SyntaxHighlighter>
            </div>
          </div>

          <div
            className="card m-2 border rounded-2"
            style={{ maxHeight: "200px" }}
          >
            <div
              class={`d-flex justify-content-between align-item-center pt-2 ${styles.misleneousclass1}`}
            >
              <p className={`text-black ${styles.font1}`}>Response</p>
              <p className="text-black" style={{ fontSize: "0.7rem" }}>
                {status?.value} <span>{status?.code}</span>
              </p>
            </div>

            <div className={`card-body border pt-1 ${styles.misleneousclass2}`}>
              <pre class="post-meta d-flex flex-wrap">
                {JSON.stringify(flag ? responseJson : {}, null, 2)}
              </pre>
            </div>
            <div class="card-footer pb-2  d-sm-flex justify-content-between align-items-center">
              <CopyButton
                color={"danger"}
                text={JSON.stringify(flag ? responseJson : {}, null, 2)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
