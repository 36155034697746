import "./App.css";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import PasswordChangeForm from "./Login/PasswordChangeForm";

import { useSelector } from "react-redux";
import ProtectedRoute from "./ProtectedRoute";
import LogIn from "./Login/LogIn";
import DashBoard from "./DashBoard/DashBoard";
 
import MyReferralLink from "../src/Components/Earnings/MyReferralLink";
import InterestIncome from "../src/Components/Earnings/InterestIncome";
import MyReferral from "../src/Components/Earnings/MyReferral";

 
import Payout_Docs from "../src/ApiDocs/Payout_Docs";
 
import Report from "./Reports/Report";
import Payments from "./Components/Payments/Payments";

import { useEffect } from "react";
import {
  config,
  getCompleteDomain,
  getServerEnvironment,
} from "./Utils/DynamicPortalFunctions";
 

import MerchantOnBoard from "./On-Board/MerchantOnBoard";
import Reports from "./Reports/reports";
import ResumeOnboarding from "./On-Board/ResumeOnboarding";
import AdharForm from "./On-Board/AdharForm";
import CompanyAddForm from "./On-Board/CompanyAddForm";
import FirstStage from "./Login/FirstStage";
import Loader from "./Components/Loader/Loader";
// import "./Assets/css/style.css";
// import "./Assets/vendor/global/global.min.js"
import Profile from "./Components/Profile/Profile";
 
import CreateUser from "./UserManagement/CreateUser";
import SubmittedUser from "./UserManagement/SubmittedUser";
import ViewUser from "./UserManagement/ViewUser";
import EditUser from "./UserManagement/EditUser";
 
import FAQ from "./Components/Faqs/FAQ";
import Tools from "./Components/Tools/Tools";
import FraudManagement from "./Components/Settings/Fraud/FraudManagement";
import Settings from "./Components/Settings/Settings";
import ErrorComponent from "./Common/ErrorBoundary/ErrorComponent";
import ApiDoc from "./ApiDocs/ApiDoc";
import PayinDocs from "./ApiDocs/PayinDocs";
import Ekyc_docs from "./ApiDocs/Ekyc_docs";
import FRM from "./ApiDocs/FRM";
import OnboardingDoc from "./ApiDocs/OnboardingDoc";

function App() {
  const { Name, logoPath } = config(getServerEnvironment(getCompleteDomain()));
  const AppNameCapitalized = Name;
  const AppName = AppNameCapitalized;
  const { loading } = useSelector((state) => state.utils);

  useEffect(() => {
    document.title = `${AppNameCapitalized}`;
    const favicon = document.getElementById("dynamicLogo");
    if (favicon) {
      favicon.href = logoPath;
    }
  }, [Name]);
  
  return (
    <>
      {loading ? <Loader /> : null}

      <Routes>
        {/* <Route path="/" element={<LogIn />} /> */}

        {/* <Route path="/MerchantOnBoard" element={<MerchantOnBoard />} />
        <Route path="/ResumeOnboarding" element={<ResumeOnboarding />} />
        <Route path="/AdharForm" element={<AdharForm />} />
        <Route path="/CompanyAddForm" element={<CompanyAddForm />} />
        <Route path="/FirstStage" element={<FirstStage />} />
        <Route path="PasswordChangeForm" element={<PasswordChangeForm />} /> */}

        {/* <Route path="/Dashboard" element={<ProtectedRoute />}>
          <Route index element={<DashBoard />} />
        </Route>
        <Route path="/Error" element={<ProtectedRoute />}>
          <Route index element={<ErrorComponent />} />
        </Route> */}
        {/* <Route path="/Archieve" element={<ProtectedRoute />}>
          <Route index element={<DashBoard />} />
        </Route> */}

        {/* <Route path="/Profile" element={<ProtectedRoute />}>
          {" "}
          <Route index element={<Profile />} />{" "}
        </Route> */}

        {/* <Route path="/Reports/:slide?" element={<ProtectedRoute />}>
          <Route index element={<Report />} />
          <Route path=":type" element={<Reports />} />
        </Route>
        <Route path="/Tools/:slidebar?" element={<ProtectedRoute />}>
          <Route index element={<Tools />} />
        </Route> */}

        {/* <Route path="/Tools" element={<ProtectedRoute />}>
          <Route path="RegisterVendor" element={<RegisterVendor />} />
          <Route path="AddWalletBalance" element={<AddPayoutBalance />} />
            <Route path="TxnCallback" element={<TransactionCallback />} />
          <Route path="PayoutTxnCallback" element={<PayoutTransactioncallback />} />
          <Route path="WithdrawAmount" element={<WithdrawInPayout />} />
          <Route path="TxnStatusCheck" element={<PayoutStatusCheck />} />
            
          <Route path="SubmittedVendors" element={<SubmittedVendor />} />
             
        </Route> */}

        {/* <Route path="/Payments" element={<ProtectedRoute />}> */}
          {/* <Route path="LinkBasedPayment" element={<LinkBasedPayments />} />
          <Route path="TapNPay" element={<TapNPay />} />
          <Route path="VirtualTerminal" element={<VirtualTerminal />} />
          <Route path="QR" element={<QR />} /> */}
          {/* <Route index element={<Payments />} /> */}
        {/* </Route> */}

        {/* <Route path="/Earnings" element={<ProtectedRoute />}>
          <Route index element={<MyReferral />} />
          <Route path="MyReferralLink" element={<MyReferralLink />} />
          <Route path="InterestIncome" element={<InterestIncome />} />
        </Route> */}
{/* 
        <Route path="/Settings" element={<ProtectedRoute />}>
          <Route index element={<Settings />} />
          <Route path="UserManagement" element={<SubmittedUser />} />
          <Route path="CreateUser" element={<CreateUser />} />
          <Route path="Notifications" element={"lkjhf"} />
          <Route path="FraudManagement" element={<FraudManagement />} />
          <Route path="EditUser" element={<EditUser />} />
          <Route path="ViewUser" element={<ViewUser />} />
        </Route> */}
        {/* <Route path="/FAQ's" element={<ProtectedRoute />}>
          <Route index element={<FAQ />} />
        </Route> */}
        <Route path="/">
          <Route path="" element={<PayinDocs />} />
          {/* <Route path="payinDocs" element={<PayinDocs />} />
          <Route path="ekycDocs" element={<Ekyc_docs />} />
          <Route path="frmDocs" element={<FRM />} />
          <Route path="onboardingDoc" element={<OnboardingDoc />} />
          <Route path="payout_docs" element={<Payout_Docs />} /> */}
        </Route>
      </Routes>
    </>
  );
}

export default App;
